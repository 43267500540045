import { helpers  } from 'vuelidate/lib/validators'
import moment from 'moment'

function getDates(date1, date2) {
  let dt1 = date1 instanceof Date ? moment(date1) : moment(date1, "M/D/YYYY", true);
  let dt2 = date2 instanceof Date ? moment(date2) : moment(date2, "M/D/YYYY", true);

  return {
    dt1,
    dt2
  }
}
const countItem = (value, property, collection, maxCount) => {
  let cnt = 0;

  for (let item of collection) {
    // Compare strings as case insensitive
    if ((typeof value == 'string' && value.trim().localeCompare(item[property], 'en', { sensitivity: 'base' }) === 0) || 
      item[property] == value) {
        cnt++;

        if (cnt >= maxCount) {
          return cnt;
        }
    }
  }

  return cnt;
}

export const unique = (property, locator) => (value, vm) => {
  let collection = locator ? locator.split('.').reduce((o,i)=>o[i], vm) : vm;
  let cnt = countItem(value, property, collection, 2);

  return cnt > 1 ? false : true;
}

export const exists = (property, locator) => (value, vm) => {
  let collection = locator ? locator.split('.').reduce((o,i)=>o[i], vm) : vm;
  let cnt = countItem(value, property, collection, 1);

  return cnt > 0 ? false : true;
}

export const date = (value) => {
  if (value instanceof Date) {
    return moment(value).isValid();
  }
  
  return moment(value, "M/D/YYYY", true).isValid()
}

export const isSameOrAfter = (property, locator, message) => 
  helpers.withParams({ type: 'isSameOrAfter', message },
  (value) => {
    let value2 = typeof locator == 'function' ? locator(property) : locator[property];
    let dates = getDates(value, value2);

    if (!dates.dt1.isValid() || !dates.dt2.isValid()) {
      return false;
    }
    
    return dates.dt1.isSameOrAfter(dates.dt2);
  });

export const isSameOrBefore = (property, locator, message) => 
  helpers.withParams({ type: 'isSameOrBefore', message },
  (value) => {
    let value2 = typeof locator == 'function' ? locator(property) : locator[property];
    let dates = getDates(value, value2);

    if (!dates.dt1.isValid() || !dates.dt2.isValid()) {
      return false;
    }
    
    return dates.dt1.isSameOrBefore(dates.dt2);
  });

export const folder = (value) => {
  let validator = /^[a-zA-Z0-9._\s]+$/g

  return validator.test(value);
}

export const lessThan = (param) => 
  (value) => {
    return value < param;
}