<template>
  <div>
    <collapsible-section
      section-title="External HPA and Unemployment"
      :value="fieldConfigMetaData.enableExternalHpaAndUnemployment"
      @input="$emit('update-field-config', { enableExternalHpaAndUnemployment: $event })"
    >
      <b-form-group
        label="HPA/Unemp IR Adjustment"
        label-for="stress-test-ir-adjust"
        :label-cols="5"
      >
        <b-select
          id="stress-test-ir-adjust"
          class="field-md"
          :value="scenarioIrAdjust"
          @input="$emit('update:scenarioIrAdjust', $event)"
          :options="scenarioIrAdjustOptions"
          size="sm"
        />
      </b-form-group>
      <b-tooltip 
        id="ir-adjust-tooltip"
        target="stress-test-ir-adjust"
        triggers="hover"
      >
        <div class="mb-1">
          None - Uses HPA files specified but does not employ the AD&amp;Co model to adjust for different HPA tunings, interest rates or random simulations.
        </div>
        <div class="mb-1">
          Off Static - Uses HPA files specified for the static (no-change) rate scenario and adjusts the HPAs for other scenarios (forward, random, tuned) using the AD&amp;Co model.
        </div>
        <div>
          Off Forward - Uses HPA files specified for the forward rate scenario and adjusts the HPAs for other scenarios (static, random, tuned) using the AD&amp;Co model.
        </div>
      </b-tooltip>

      <file-select-with-checkbox 
        label="Use Custom HPA File"
        :value="useCustomHpaFile"
        @input="$emit('update:useCustomHpaFile', $event)"
        :file-name="fileNames.customHpaFile" 
      />

      <file-select-with-checkbox 
        label="Use Custom Unemployment File"
        :value="useCustomUnempFile"
        @input="$emit('update:useCustomUnempFile', $event)"
        :file-name="fileNames.customUnempFile" 
      />

      <b-form-checkbox
        id="stress-test-local-adjust"
        :checked="scenarioLocalityAdjust"
        @input="$emit('update:scenarioLocalityAdjust', $event)"
      >
        HPA/Unemp Locality Adjustment
      </b-form-checkbox>

      <b-form-checkbox
        v-b-tooltip.hover.right
        id="stress-test-season-adjust"
        :checked="scenarioAddSeasonality"
        @input="$emit('update:scenarioAddSeasonality', $event)"
        title="Adds seasonality to the HPA files as computed by the AD&amp;Co model."
        inline
      >
        Add Seasonality to HPA
      </b-form-checkbox>

      <file-select-with-checkbox 
        label="Use HPA Zip Map"
        :value="useHpaZipMap"
        @input="$emit('update:useHpaZipMap', $event)"
        :file-name="fileNames.hpaZipMap" 
      />

      <file-select-with-checkbox 
        label="Use Unemployment Zip Map"
        :value="useUnempZipMap"
        @input="$emit('update:useUnempZipMap', $event)"
        :file-name="fileNames.unempZipMap" 
      />
    </collapsible-section>
  </div>
</template>

<script>
import CollapsibleSection from './CollapsibleSection.vue'
import FileSelectWithCheckbox from './FileSelectWithCheckbox.vue'
import { scenarioIrAdjustOptions } from '../js/options/scenarioIrAdjustOptions'
import { fileNames } from '../js/fileInfo'

export default {
  props: {
    useCustomHpaFile: { type: Boolean, required: true },
    useCustomUnempFile: { type: Boolean, required: true },
    scenarioIrAdjust: { type: String, required: true },
    scenarioLocalityAdjust: { type: Boolean, required: true },
    scenarioAddSeasonality: { type: Boolean, required: true },
    useHpaZipMap: { type: Boolean, required: true },
    useUnempZipMap: { type: Boolean, required: true },
    fieldConfigMetaData: { type: Object, required: true }
  },
  data () {
    return {
      fileNames,
      scenarioIrAdjustOptions
    }
  },
  components: {
    CollapsibleSection,
    FileSelectWithCheckbox
  }
}
</script>