import { required, integer, between, decimal, and } from 'vuelidate/lib/validators'
import { date, isSameOrAfter, isSameOrBefore, lessThan } from './validators'
import moment from 'moment'

function getModelDataDate(modelDataDate) {
  if (modelDataDate && modelDataDate.length == 6) {
    let year = modelDataDate.substring(0, 4);
    let month = modelDataDate.substring(4);
  
    let date = moment(`${month}/1/${year}`, "M/D/YYYY");

    return date
      .add(1, 'months')
      .subtract(1, 'days')
      .format("M/D/YYYY");
  } else {
    // Model data date not set, return today
    return moment().format("M/D/YYYY");
  }
}

export function validations() {
  let v = {
    settings: {
      monteCarlo: {
        paths: {
          required,
          integer,
          between: between(5, 10000)
        }
      },
      tradeDate: {
        required,
        date,
        isSameOrBefore: isSameOrBefore('modelDataDate', (property) => {
          return getModelDataDate(this.settings[property]);
        }, `Trade Date cannot be after AD&Co data files date of ${getModelDataDate(this.settings.modelDataDate)}`)
      },
      irProcessShift: {
        decimal,
        required
      }
    }
  }

  if (this.settings.useHedgeFile) {
    v.settings.hedgeQuote = {
      required,
      decimal,
      between: between(-1000, 10000)
    };
    v.settings.hedgeSettleDate = {
      required,
      date,
      isSameOrAfter: isSameOrAfter('tradeDate', this.settings, "Hedge Settle Date cannot be before Trade Date")
    };
  }

  if (this.settings.payup) {
    v.settings.payupHorizon = {
      required,
      decimal,
      between: between(0, 360)
    }
  }

  if (this.settings.enableCapitalCharge) {
    v.settings.riskFreeRate = {
      required,
      decimal,
      between: between(0, 100)
    }
    v.settings.confidenceLevel0 = {
      required,
      decimal,
      confidenceInterval: and(between(0, 100), lessThan(100))
    }
    v.settings.confidenceLevel1 = {
      required,
      decimal,
      confidenceInterval: and(between(0, 100), lessThan(100))
    }
    v.settings.confidenceLevel2 = {
      required,
      decimal,
      confidenceInterval: and(between(0, 100), lessThan(100))
    }
    v.settings.confidenceLevel3 = {
      required,
      decimal,
      confidenceInterval: and(between(0, 100), lessThan(100))
    }
    v.settings.returnOnEquity0 = {
      required,
      decimal,
      between: between(0, 100)
    }
    v.settings.returnOnEquity1 = {
      required,
      decimal,
      between: between(0, 100)
    }
    v.settings.returnOnEquity2 = {
      required,
      decimal,
      between: between(0, 100)
    }
    v.settings.riskFreeRate = {
      required,
      decimal,
      between: between(0, 100)
    }
  }

  return v;
}