<template>
  <div class="portfolio">
    <b-alert
      class="ml-3 mr-3"
      :show="!!errorMessage"
      variant="danger"
    >
      {{ errorMessage }}
    </b-alert>
    <loading-indicator v-if="loading" />
    <div
      v-else-if="!errorMessage"
      v-disable-all="analysisRunning"
    >
      <b-alert
        :show="!tabValid('Portfolio')"
        variant="danger"
      >
        {{ this.gridCount == 0 ? 'Please add at least one item to the portfolio' : 'Please fix the validation errors shown below' }}
      </b-alert>
      
      <b-navbar
        toggleable="sm"
      >
        <b-navbar-nav>
          <b-nav-form>
            <div class="modern-tab-buttons">
              <b-button
                variant="outline-secondary"
                v-b-tooltip.hover.bottom
                title="Save portfolio changes"
                @click="$refs.portfolioGrid.save()"
              >
                <font-awesome-icon icon="save" />
                Save
              </b-button>
            </div>
          </b-nav-form>
        </b-navbar-nav>

        <b-navbar-nav>
          <b-nav-form>
            <div class="modern-tab-buttons">
              <!-- <b-button
                variant="outline-secondary"
                v-b-tooltip.hover.bottom
                title="Import portfolio data from local file"
                :disabled="portfolioFile.status == fileStatus.uploading"
              >
                <upload-button
                  ref="importBtn"
                  :disabled="portfolioFile.status == fileStatus.uploading"
                  @input="confirmReplaceData($event)"
                  @error="fileError($event)"
                >
                  <font-awesome-icon icon="download" />
                  {{ portfolioFile.status == fileStatus.uploading ? 'Importing...' : 'Import' }}
                </upload-button>
              </b-button> -->

              <b-dropdown 
                v-if="!licenseInfo.demoMode || isAdmin"
                variant="outline-secondary"
                split 
                text="Import"
                v-b-tooltip.hover.bottom
                title="Import portfolio data"
                :disabled="!canUpload"
              >
                <template 
                  #button-content
                >
                  <upload-button
                    ref="importBtn"
                    :disabled="!canUpload"
                    @input="confirmReplaceData($event)"
                    @error="fileError($event)"
                  >
                    <font-awesome-icon icon="download" />
                    {{ portfolioFile.status == fileStatus.uploading ? 'Importing...' : 'Import' }}
                  </upload-button>
                </template>
                <b-dropdown-item 
                  @click.native.capture.stop="importClick"
                >
                  <upload-button
                    ref="importMenuItem"
                    :disabled="!canUpload"
                    @input="confirmReplaceData($event)"
                    @error="fileError($event)"
                  >
                    Import from file...
                  </upload-button>
                </b-dropdown-item>
                <b-dropdown-item @click="confirmReplaceData($event, true)">
                  Load sample portfolio
                </b-dropdown-item>
              </b-dropdown>
              <b-btn
                variant="outline-secondary"
                v-b-tooltip.hover.bottom
                title="Load sample portfolio data. File import is not available in the demo application, but is an option in the full version."
                @click="confirmReplaceData($event, true)"
                v-else
              >
                <font-awesome-icon icon="download" />
                Load Sample Portfolio
              </b-btn>

              <div
                class="portfolio-upload"
                v-if="portfolioFile.status == fileStatus.uploading"
              >
                <upload-progress  
                  :progress="portfolioFile.uploadProgress"
                  @cancel-upload="cancelUpload(portfolioFile.uploadCancelToken)"
                />
              </div>

              <b-button
                variant="outline-secondary"
                v-b-tooltip.hover.bottom
                title="Displays file import layout with example data"
                @click="showFileLayout(portfolioFile)"
              >
                <font-awesome-icon icon="folder" />
                File Layout
              </b-button>
            </div>
          </b-nav-form>
        </b-navbar-nav>

        <b-navbar-nav
          v-if="activeAnalysis.assetType != 'AutoLoans'"
        >
          <b-nav-form>
            <div class="modern-tab-buttons">
              <b-dropdown 
                variant="outline-secondary"
                text="Quick Filters"
                v-b-tooltip.hover.bottom
                title="Apply filters to positions and fields"
                :disabled="gridCount == 0 && !gridFiltered"
              >
                <template 
                  #button-content
                >
                  <font-awesome-icon icon="filter" />
                  {{ quickFilterLabel }}
                </template>
                <b-dropdown-form>
                  <b-form-checkbox-group
                    id="quick-filters-rate-type"
                    v-model="selectedRateTypeFilters"
                    :options="quickFilterOptions.rateType"
                    name="quick-filter-rate-type"
                  />
                  <b-dropdown-divider />
                  <div
                    v-if="!isOnlyLicensingAgency && !isOnlyLicensingNonAgency && activeAnalysis.assetType == assetTypes.singleFamilyLoans"
                  >
                    <b-form-checkbox-group
                      id="quick-filters-issuer-type"
                      v-model="selectedIssuerTypeFilters"
                      :options="quickFilterOptions.issuerType"
                      name="quick-filter-issuer-type"
                    />
                    <b-dropdown-divider />
                  </div>
                  <b-button
                    size="sm"
                    title="Clear quick filters"
                    @click="clearFilters()"
                    :disabled="selectedRateTypeFilters.length == 0 && selectedIssuerTypeFilters.length == 0"
                  >
                    Clear
                  </b-button>
                  <b-button
                    class="ml-2"
                    size="sm"
                    title="Clear all grid filters"
                    @click="clearGridFilters()"
                    :disabled="!gridFiltered"
                  >
                    Clear All
                  </b-button>
                </b-dropdown-form>
              </b-dropdown>
              <b-button
                variant="outline-secondary"
                v-b-tooltip.hover.bottom
                title="Add new position"
                @click="$refs.portfolioGrid.insertRow()"
              >
                <font-awesome-icon icon="plus-square" />
                Add Position
              </b-button>
            </div>
          </b-nav-form>
        </b-navbar-nav>

        <b-navbar-nav>
          <b-nav-form>
            <div class="modern-tab-buttons">
              <b-button-group class="button-group">
                <b-button
                  variant="outline-secondary"
                  v-b-tooltip.hover.bottom
                  title="Undo last portfolio change"
                  @click="$refs.portfolioGrid.undo()"
                  :disabled="!canUndo"
                >
                  <font-awesome-icon icon="undo" />
                  Undo
                </b-button>
                <b-button
                  variant="outline-secondary"
                  v-b-tooltip.hover.bottom
                  title="Redo last portfolio change"
                  @click="$refs.portfolioGrid.redo()"
                  :disabled="!canRedo"
                >
                  <font-awesome-icon icon="redo" />
                  Redo
                </b-button>
              </b-button-group>
            </div>
          </b-nav-form>
        </b-navbar-nav>

        <b-navbar-nav>
          <b-nav-form>
            <div class="modern-tab-buttons">
              <b-button-group class="button-group">
                <b-button
                  variant="outline-secondary"
                  v-b-tooltip.hover.bottom
                  title="Copy selected data"
                  @click="$refs.portfolioGrid.copy()"
                  :disabled="gridCount == 0"
                >
                  <font-awesome-icon icon="copy" />
                  Copy
                </b-button>
                <b-button
                  variant="outline-secondary"
                  v-b-tooltip.hover.bottom
                  title="Paste copied data"
                  @click="$refs.portfolioGrid.paste()"
                  :disabled="!canPaste"
                >
                  <font-awesome-icon icon="paste" />
                  Paste
                </b-button>
              </b-button-group>
            </div>
          </b-nav-form>
        </b-navbar-nav>


        <b-navbar-nav>
          <b-nav-form>
            <div class="modern-tab-buttons">
              <b-button-group class="button-group">
                <b-button
                  variant="outline-secondary"
                  v-b-tooltip.hover.bottom
                  title="Deletes all portfolio data"
                  @click="confirmDelete"
                  :disabled="gridCount == 0"
                >
                  <font-awesome-icon icon="times-circle" />
                  Delete All
                </b-button>
                <b-button
                  variant="outline-secondary"
                  v-b-tooltip.hover.bottom
                  title="Deletes selected positions"
                  @click="$refs.portfolioGrid.deleteSelectedRows()"
                  :disabled="gridCount == 0"
                >
                  <font-awesome-icon icon="trash" />
                  Delete Selected
                </b-button>
              </b-button-group>
            </div>
          </b-nav-form>
        </b-navbar-nav>

        <b-navbar-nav
          class="record-count ml-auto"
        >
          <b-nav-form>
            <div id="grid-pager" />
          </b-nav-form>
        </b-navbar-nav>
      </b-navbar>

      <scroll-container>
        <portfolio-grid
          ref="portfolioGrid"
          @grid-update="portfolioUpdated"
          @can-undo="enableUndo($event)"
          @can-redo="enableRedo($event)"
          @can-paste="enablePaste($event)"
          @grid-paging="handlePageChange($event)"
        />
      </scroll-container>
    </div>

    <modal-dialog
      ref="confirmDeleteDialog"
      title="Delete Data"
      :prompt="`Are you sure you want to delete all portfolio data?`"
      :action="deleteData"
      error-message="An error occurred deleting the data."
      confirm-button-text="Delete"
    />

    <modal-dialog
      ref="confirmReplaceDialog"
      title="Replace Data"
      :prompt="`Do you want to replace the existing Portfolio data?`"
      error-message="An error occurred replacing the data."
      :action="loadSamplePortfolio"
      @confirmed="beginImport"
      @input="clearFileSelect"
    >
      <template #footer="{ ok, cancel }">
        <b-btn
          @click="cancel()"
        >
          Cancel
        </b-btn>
        <b-btn
          variant="primary"
          @click="replaceExistingData(false, ok)"
        >
          Keep Existing Data
        </b-btn>
        <b-btn
          variant="danger"
          @click="replaceExistingData(true, ok)"
        >
          Replace Existing Data
        </b-btn>
      </template>
    </modal-dialog>

    <modal-dialog
      ref="importDisabledDialog"
      title="Import Disabled"
      :prompt="`File imports are not available for demo accounts. Please select 'Load sample portfolio'.`"
      confirm-button-text="OK"
      ok-only
    />

    <modal-dialog
      ref="unsavedChangesDialog"
      title="Unsaved Portfolio Changes"
      :prompt="unsavedChangesPrompt"
    >
      <template #footer="{ ok, cancel }">
        <b-btn
          @click="cancelNavigation(cancel)"
        >
          Cancel
        </b-btn>
        <b-btn
          v-if="!runAfterClose"
          variant="danger"
          @click="discardChanges(ok)"
        >
          Discard Changes
        </b-btn>
        <b-btn
          v-if="allowSave"
          variant="primary"
          @click="savePortfolio(ok, runAfterClose)"
        >
          Save{{ runAfterClose ? ' and Run Analysis' : '' }}
        </b-btn>
      </template>
    </modal-dialog>

    <import-results-dialog
      ref="importDialog"
      :import-results="importResults"
    />

    <file-layout-dialog
      ref="fileLayoutDialog"
      :file="clickedFile"
    />
  </div>
</template>

<script>
import UploadProgress from './UploadProgress.vue'
import UploadButton from './UploadButton.vue'
import LoadingIndicator from './LoadingIndicator.vue'
import ModalDialog from './ModalDialog.vue'
import ImportResultsDialog from './ImportResultsDialog.vue'
import FileLayoutDialog from './FileLayoutDialog.vue'
import ScrollContainer from './ScrollContainer.vue'
import PortfolioGrid from './PortfolioGrid.vue'
import formatters from '../js/formatters'
import { fileStatus, fileNames } from '../js/fileInfo'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import fileUpload from '../js/mixins/fileUpload'
import { assetTypes } from '../js/options/assetType'
import api from '../api'

export default {
  props: {
    id: { type: [String, Number], required: true }
  },
  data () {
    return {
      loading: false,
      statusInfo: [
        { label: 'Missing', variant: 'danger' },
        { label: 'Selected', variant: 'success' },
        { label: 'Imported', variant: 'success' },
        { label: 'Optional', variant: 'info' },
        { label: 'Uploading', variant: 'warning' }
      ],
      fileStatus,
      assetTypes,
      errorMessage: null,
      clickedFile: {},
      importResults: {},
      selectedUpload: null,
      fileLayout: [],
      useTemplate: false,
      replaceData: false,
      gridCount: 0,
      gridFiltered: false,
      canUndo: false,
      canRedo: false,
      canPaste: false,
      navigation: false,
      loadSampleData: false,
      quickFilterOptions: {
        rateType: ['Fixed', 'ARM'],
        issuerType: ['Agency', 'Non-Agency']
      },
      selectedRateTypeFilters: [],
      selectedIssuerTypeFilters: [],
      gridPaging: false,
      unsavedChangesPrompt: '',
      allowSave: false,
      runAfterClose: false
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != 'Results' && this.$refs.portfolioGrid.changesPending()) {
      this.navigation = next;
      this.handleUnsavedChanges(false);
    } else {
      next();
    }
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  computed: {
    ...mapState(['fileList', 'activeAnalysis', 'licenseInfo']),
    ...mapGetters(['tabValid', 'analysisRunning', 'isAdmin', 'isOnlyLicensingAgency', 'isOnlyLicensingNonAgency']),

    portfolioFile () {
      return this.fileList[fileNames.loans];
    },
    canUpload () {
      return (this.portfolioFile.status != fileStatus.uploading && !this.analysisRunning);
    },
    quickFilterLabel () {
      if (this.selectedRateTypeFilters.length == 0 && this.selectedIssuerTypeFilters.length == 0) {
        return 'All Positions';
      }

      let sep = this.selectedIssuerTypeFilters.length > 0 && this.selectedRateTypeFilters.length > 0 ? ', ' : '';
      return `${this.selectedIssuerTypeFilters.join(', ')}${sep}${this.selectedRateTypeFilters.join(', ')}`
    }
  },
  methods: {
    ...mapMutations(['updateFile', 'enableHedges']),
    ...mapActions(['getInputsList', 'autoSave']),

    async confirmReplaceData (file, loadSampleData = false) {
      this.hideTooltips();
      this.selectedUpload = file;
      this.loadSampleData = loadSampleData

      if (this.gridCount > 0) {
        this.$refs.confirmReplaceDialog.showDialog();
      } else {
        if (loadSampleData) {
            await this.loadSamplePortfolio();
          } else {
            await this.beginUpload();
          }
      }
    },

    async loadSamplePortfolio () {
      // only load if loadSampleData flag is set, else we are import from file
      if (this.loadSampleData) {
        this.clearFilters();
        await this.$http.post(`analyses/${this.activeAnalysis.id}/templates/${this.portfolioFile.inputType}?overwrite=${this.replaceData}`);
        this.$refs.portfolioGrid.refresh();
      }
    },

    async beginImport () {
      if (!this.loadSampleData) {
        this.clearFilters();
        await this.beginUpload();
      }
    },

    hideTooltips () {
      setTimeout(() => {
        this.$root.$emit('bv::hide::tooltip');
      }, 200);
    },

    replaceExistingData(replace, ok) {
      this.replaceData = replace;
      ok();
    },

    async beginUpload () {
      try {

        if (this.replaceData) {
          // Delete any existing data
          await api.deletePositions(this.activeAnalysis.id);
        }

        this.importResults = await this.uploadFile(this.activeAnalysis.id, this.portfolioFile, this.selectedUpload, this.clearFileSelect);
      
        if (this.importResults.errors && this.importResults.errors.length) {
          // Show import results
          this.$refs.importDialog.showDialog();
        }

        this.$refs.portfolioGrid.refresh();
        this.$refs.portfolioGrid.validateGrid();

      } catch (err) {
        let msg = err.message || "";
        console.log(err)

        this.messageBox('Upload Error', `An error occurred uploading your file. ${msg}`)
          .then(() => {
            this.updateFile({ 
              fileName: this.portfolioFile.fileName, 
              fileInfo: {
                originalName: null,
                status: fileStatus.missing,
                recordCount: null,
                modifiedDate: null,
                file: null,
                uploadProgress: 0,
                uploadCancelToken: null
              }
            });
          });
      }

      this.clearFileSelect();
    },

    confirmDelete () {
      this.hideTooltips();
      this.$refs.confirmDeleteDialog.showDialog();
    },

    async deleteData () {
      await this.$http.delete(`analyses/${this.activeAnalysis.id}/inputs/${this.portfolioFile.route}`);
      
      this.updateFile({ 
        fileName: this.portfolioFile.fileName, 
        fileInfo: {
          originalName: null,
          status: fileStatus.missing,
          recordCount: null,
          modifiedDate: null,
          file: null,
          uploadProgress: 0,
          uploadCancelToken: null
        }
      });

      this.clearFileSelect();
      this.clearFilters();
      this.$refs.portfolioGrid.refresh();
    },
    
    fileError (ext) {
      this.messageBox('Invalid file extension', `Invalid file extension: .${ext}. Please upload a tab delimited text file.`);

      this.clearFileSelect();
    },
    clearFileSelect () {
      if (!this.licenseInfo.demoMode || this.isAdmin) {
        this.$refs.importBtn.clear();
        this.$refs.importMenuItem.clear();
      }
    },
    messageBox (title, message) {
      return this.$bvModal.msgBoxOk(message, {
        title: title,
        headerClass: 'pl-2 pr-2 pt-1 pb-1',
        footerClass: 'p-2 border-top-0'
      });
    },
    setHedgeFile (enabled) {
      this.enableHedges(enabled);
      let data = this.activeAnalysis.settings
      data.runType = this.activeAnalysis.runType
      this.autoSave({ saveAction: 'saveAnalysisSettings', isValid: true, id: this.activeAnalysis.id, data: data });
    },
    showFileLayout (fileInfo) {
      this.clickedFile = fileInfo;

      this.$nextTick(() => {
        this.$refs.fileLayoutDialog.showDialog();
      });
    },
    importClick () {
      if (this.licenseInfo.demoMode && !this.isAdmin) {
        this.$refs.importDisabledDialog.showDialog();
      } else {
        // Simulate a click to close the drop down
        this.$nextTick(() => {
          document.body.click();
        });
      }
    },
    portfolioUpdated (data) {
      this.gridCount = data.count;
      this.gridFiltered = data.filtered;
    },
    handleUnsavedChanges (runAnalysis) {
      let isValid = this.$refs.portfolioGrid.validateGrid();
      this.allowSave = isValid;
      this.runAfterClose = runAnalysis;

      if (!isValid && !this.runAfterClose) {
        this.unsavedChangesPrompt = 'Your portfolio has unsaved changes with validation errors. Would you like to discard your changes? This cannot be undone.'
      } else if (!isValid && this.runAfterClose) {
        this.unsavedChangesPrompt = 'Your portfolio has unsaved changes with validation errors. Please fix the errors and save before running your analysis.'
      } else {
        this.unsavedChangesPrompt = 'Your portfolio has unsaved changes. Would you like to save your changes?'
      }
      this.$refs.unsavedChangesDialog.showDialog();
    },
    async savePortfolio (confirm, runAfterClose) {
      await this.$refs.portfolioGrid.$options.grid.save();
      this.continueNavigation(confirm);
      if (runAfterClose) this.$emit('runAnalysis');
    },
    discardChanges (confirm) {
      this.$refs.portfolioGrid.clearPendingChanges();
      this.$refs.portfolioGrid.refresh();
      this.$refs.portfolioGrid.validateGrid();
      this.continueNavigation(confirm);
    },
    cancelNavigation (cancel) {
      if (this.navigation) {
        // Cancel navigation
        this.navigation(false);
      }

      cancel();
    },
    continueNavigation (confirm) {
      if (this.navigation) {
        this.navigation();
      }
      confirm();
    },
    beforeWindowUnload (e) {
      if (this.$refs.portfolioGrid.changesPending()) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
    enableUndo(enabled) {
      if (!enabled) {
        this.hideTooltips();
      }
      this.canUndo = enabled;
    },
    enableRedo(enabled) {
      if (!enabled) {
        this.hideTooltips();
      }
      this.canRedo = enabled;
    },
    enablePaste(enabled) {
      if (!enabled) {
        this.hideTooltips();
      }
      this.canPaste = enabled;
    },
    clearFilters() {
      this.selectedRateTypeFilters = []
      this.selectedIssuerTypeFilters = []
    },
    clearGridFilters() {
      this.clearFilters();
      this.$refs.portfolioGrid.clearAllFilters();
    },
    hasFilters() {
      return this.$refs.portfolioGrid ? this.$refs.portfolioGrid.hasFilters() : false;
    },
    handlePageChange(evt) {
      if (this.$refs.portfolioGrid.changesPending()) {
        this.gridPaging = true;
        this.navigation = evt.navigate;
        this.handleUnsavedChanges(false);
      } else {
        evt.navigate();
      }
    }
  },
  watch: {
    selectedRateTypeFilters: {
      handler(newValue, oldValue) {
        this.$refs.portfolioGrid.quickFilterRateType(newValue)
      },
      deep: true
    },
    selectedIssuerTypeFilters: {
      handler(newValue, oldValue) {
        this.$refs.portfolioGrid.quickFilterIssuerType(newValue)
      },
      deep: true
    }
  },
  components: {
    UploadButton,
    LoadingIndicator,
    ModalDialog,
    ImportResultsDialog,
    FileLayoutDialog,
    ScrollContainer,
    PortfolioGrid,
    UploadProgress
  },
  mixins: [formatters, fileUpload]
}
</script>

<style scoped>
.badge {
  padding: 0.5em 1em;
}

.table {
  cursor: default;
}

.table > tbody > tr.invalid > td {
  background-color: #f8d7da;
}

.table > tbody > tr.invalid:hover > td {
  background-color: #fad3d6;
}

.template-icon {
  font-size: 1.5em;
}

.form-inline label {
  margin-right: 5px;
}

.btn {
  font-size: 0.9em;
}

.button-group {
  margin-left: 15px;
}

.dropdown-item > .svg-inline--fa {
    margin-right: 2px;
}

.portfolio-upload {
  width: 250px;
  padding: 0 10px;
  margin-left: 15px;
}

.record-count {
  font-weight: bold;
}

.dropdown-menu label {
  justify-content: left;
}

@media (min-width: 576px) {
  .portfolio .navbar-expand-sm {
      flex-flow: row wrap;
      padding-top: 0;
  }
  .portfolio .navbar-expand-sm .navbar-nav {
    padding-top: 0.5rem;
  }
  #grid-pager {
    min-width: 450px;
  }
}
</style>

