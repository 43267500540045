import api from '../../api'

const resultTypes = {
  summary: 0,
  error: 1,
  portfolio: 2,
  scenario: 3,
  loanScenario: 4,
  cashflow: 5,
  custom: 6,
  msrDecomp: 7
}

const columnMapper = (columns) => {
  // Make sure CfType is mapped
  if (!columns.some(c => c.binding == 'cashFlowType')) {
    columns.push({ header: 'cashFlowType', binding: 'cashFlowType' });
  }

  // Return converter function
  return (d) => {
    let row = {}

    // Only include bound columns
    // This reduces memory usage and allows downloading reports containing only bound columns
    columns.forEach(c => {
      if (d[c.binding]) {
        row[c.binding] = d[c.binding]
      }
    })

    return row;
  }
}

const getResultData = async (analysisId, collectionName, columns, aggregate, reportId) => {
  return await api.getResults(analysisId, collectionName, 1000, columns, aggregate, reportId);
};

const getFileData = async (analysis, fileName, columns, folder = 'results') => {
  try {
    return await api.getFile(analysis.id, fileName, folder, columns ? columnMapper(columns) : null);
  }
  catch (err) {
    // ignore errors, console will still show request error
  }
};

// const getHedgeFileResultsName = (hedgeAveragingType, dollars) => {
//   if (dollars || hedgeAveragingType == 0) {
//     return 'cftype_cumresults';
//   } else if (hedgeAveragingType == 1) {
//     return 'nohacftype_cumresults';
//   } else if (hedgeAveragingType == 2) {
//     return 'neghacftype_cumresults';
//   }
// }

const updateTotals = (totals, options, footer = true) => {
  const useHedgeFile = options.calcs.useHedgeFile;

  // Swap MSR and Hedge order so MSR is first
  if (useHedgeFile && totals[0].cashFlowType == "Hedge" && totals[1].cashFlowType == "MSR") {
    [totals[0], totals[1]] = [totals[1], totals[0]];
  }

  totals.forEach(r => {
    switch (r.cashFlowType) {
      case "Total":
        r.loanId = 'Grand Total';
        r.multiple = ""; // Remove IO Multiple for Grand Total
        break;
      case "Hedge":
        r.loanId = useHedgeFile ? 'Hedge Total' : 'Total';
        break;
      case "MSR":
        r.loanId = useHedgeFile ? 'MSR Total' : 'Total';
        break;
      case "Loan":
        r.loanId = 'Total';
        break;
    }

    r.footer = footer;

    // Convert -1 and -nan to blanks
    for (const [key, value] of Object.entries(r)) {
      if (key != "cashFlowType" && (value == -1 || value == "-nan")) {
        r[key] = "";
      }
    }
  });
}

// ResultItem options object
// { 
//   name: [Required] Report name to display
//   resultType: [Required] Result type value, see resultTypes
//   default: Boolean value indicating report selected by default
//   load: Boolean value indicating if data should be load immediately instead of on-demand
//   columns: Function to get the report columns
//   getData: Function to get the report data 
//   fileName: file name to load if a custom getData function is not provided
//   subItems: an array of ResultItem to display as sub reports of the main ResultItem
//   displayType: 'table' or 'chart'
//   chartLayout: layout options for Plotly chart
//   collectionName: Collection name used for odata queries or to export results data from the web service
//   loadColumns: Function to asyncronously load column meta data
//   reportLevel: 0 if result should appear in results dropdown, 1 otherwise
//   parentReport: Name of related top-level report
//   groupName: Display name for top-level reports; shown in results dropdown
//   shortName: Short name used for related report toggling
// }

export default class ResultItem {
  constructor(options) {
    const getDataByName = async (analysis, options) => {
      let selectedPath = this.path || options.path;
      let selectedLoanId = this.selectedLoanId || options.selectedLoanId;
      let selectedCashFlows = this.selectedCashFlows || options.selectedCashFlows;
      let scenarioType = this.scenarioType || options.scenarioType;

      if (this.resultType === resultTypes.cashflow) {
        if (selectedCashFlows) {
          try {
            return await api.getCashFlowFile(analysis.id, null, selectedCashFlows, selectedLoanId, selectedPath, scenarioType);
          } catch (error) {
            //debugger
            this.errorInCashFlow = true;
            this.errorInCashFlowMessage = error.response.data.message;
            return []
          }
        }
      }
      else if (this.fileName) {
        return await getFileData(analysis, this.fileName, null, this.folder);
      } else if (this.collectionName) {
        const cols = this.columns(options);
        return await getResultData(analysis.id, this.collectionName, cols);
      }
    };

    if (!options) {
      throw("You must provide ResultItem options");
    }

    if (!options.name || options.resultType == null) {
      throw("The options name and resultType are required.");
    }

    if (!options.fileName && !options.collectionName && !options.getData && !options.subItems) {
      throw("The options fileName, collectionName, getData, or subItems are required.");
    }
    this.id = options.id || null;
    this.name = options.name;
    this.fileName = options.fileName;
    this.folder = options.folder || 'results';
    this.resultType = options.resultType;
    this.data = null;

    this.load = options.load || false;
    this.default = options.default || false;
    this.columns = options.columns;
    this.getData = options.getData || getDataByName;
    this.subItems = options.subItems || null;
    this.displayType = options.displayType || 'table';
    this.chartOptions = options.chartOptions || null;
    this.resultCount = options.resultCount || null;
    this.collectionName = options.collectionName || null;
    this.loadColumns = options.loadColumns || (async () => { this.columnsLoaded = true });
    this.columnsLoaded = false;
    this.aggregateData = options.aggregateData || false;
    this.includeTotals = options.includeTotals || false;
    this.reportLevel = options.reportLevel || 0;
    this.parentReport = options.parentReport || null,
    this.groupName = options.groupName || options.name;
    this.shortName = options.shortName || options.name;    
    this.selectedCashFlows = options.selectedCashFlows || false,
    this.selectedLoanId = options.selectedLoanId || null,
    this.selectedPath = options.path || null, 
    this.errorInCashFlow =  options.errorInCashFlow || false, 
    this.errorInCashFlowMessage=options.errorInCashFlowMessage || null  }
}

export { resultTypes, getFileData, updateTotals, getResultData }