import ResultItem, { resultTypes } from './ResultItem'
import { getCashflowColumns } from './cashFlowColumns';
import ColorGenerator from './ColorGenerator'
import api from '../../api'

export default function createCashFlowChartItem(id, name, fileName, calcs, folder, selectedCashFlows,selectedLoanId, path, assetType, scenarioType) {
  let cols = getCashflowColumns({ calcs, assetType });

  return new ResultItem ({
    id,
    name,
    fileName, 
    folder,
    resultType: resultTypes.cashflow,
    default: true,
    displayType: 'chart',
    chartOptions: {
      title: 'Cash Flows',
      fields: cols.filter(c => c.binding != 'Date').map(a => ({ 
        fieldName: a.header, 
        selected: a.selected,
        defaultAxis: a.defaultAxis
      }))
    },
    errorInCashFlow: false,
    errorInCashFlowMessage: null,
    async getData (analysis) {
      let data = [];
      try {
        data = await api.getCashFlowFile(analysis.id, null, selectedCashFlows, selectedLoanId, path, scenarioType);
        this.errorInCashFlow =false
        this.errorInCashFlowMessage= null

      } catch (error) {
        this.errorInCashFlowMessage= error.response.data.message;
        this.errorInCashFlow = true
        data = []
      }
      if (!data) {
        return [];
      }

      let vectors = {}
  
      // Create an array for each column
      data.forEach(r => {
        cols.forEach(c => {
          if (!vectors[c.binding]) {
            vectors[c.binding] = [];
          }

          if (c.binding == 'Date') {
            vectors[c.binding].push(r[c.binding]);
          } else {
            vectors[c.binding].push(parseFloat(r[c.binding]));
          }
          
        });
      });
  
      let colorGen = new ColorGenerator();
  
      let chartData = cols.filter(c => c.binding != 'Date').map((a, i) => ({
        name: a.header,
        fieldName: a.header,
        hoverlabel: {namelength :-1},
        line: { shape: 'spline', smoothing: 1.3, width: 2, color: colorGen.getColor(i) },
        x: vectors.Date,
        y: vectors[a.binding],
        yaxis: a.defaultAxis == 'y2' ? 'y2' : null
      }));

      return chartData;
    }
  });
}

