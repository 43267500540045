import { assetTypes } from '../options/assetType'
import { runTypes } from '../options/runType'
import { DataType } from '@grapecity/wijmo'
import { decimal, integer, between, minValue, required, minLength, maxLength } from 'vuelidate/lib/validators'
import moment from 'moment'
import store from '../../store'

const requiredIfArm = (item) => item?.rateFixed || (item?.rateFixed == false && item?.index);

export default function(analysis, isHedge = false) {
  const isMsr = analysis.assetType == assetTypes.servicing;
  const isAutoLoans = analysis.assetType == assetTypes.autoLoans;
  const isSingleFamily = analysis.assetType == assetTypes.singleFamilyLoans;
  const isMultiFamily = analysis.assetType == assetTypes.multiFamilyLoans;
  const isModelOnly = analysis.runType == runTypes.loanDynamics;
  const isPools = analysis.positionFormat == "Pool";
  const isLdm3 = store.getters.ldmVersionMajor >= 3;
  const module = store.state.licenseInfo.modules.find(m => m.assetType == analysis.assetType);
  const hasTrendedCredit = store.state.licenseInfo.models.some(m => m.id == "ImpactTrendedCredit" && moment().isBefore(m.licenseExpiration) && module.supportsTrendedCredit);
  
  let cols = [
    //{ binding: 'id', header: 'Id', isReadOnly: true },

    { binding: 'run', header: 'Run', isRequired: true, visible: isModelOnly, dataType: DataType.Boolean, desc: 'Set to true to include the loan/pool when the analysis is run', example: '1', type: 'boolean', required: 'Required', validations: { required }},
    { binding: 'loanId', header:  isPools ? 'Pool ID' :'Loan ID', isRequired: true, desc: `Unique name or number for ${isPools ? 'pool' : 'loan'}`, example: '1001', type: 'string', validations: { required } },
    { binding: 'aggregationWeight', header: 'Weight', isRequired: false, visible: isModelOnly, dataType: DataType.Number, desc: 'Custom weight to use during aggregation', example: '150000.00', type: 'double', required: 'Optional', validations: { decimal } },
    { binding: 'issuer', header: 'Issuer', isRequired: true, visible: (isSingleFamily || isMultiFamily) && isModelOnly, desc: 'Loan Type (government agency or nonagency): FANNIE_MAE, FREDDIE_MAC, GINNIE_MAE, NONAGENCY_SUBPRIME, NONAGENCY_PRIME, NONAGENCY_OTHER', example: 'FREDDIE_MAC', type: 'string', required: 'Required', validations: { required } },
    { binding: 'rateFixed', header: 'Rate Fixed', isRequired: true, visible: !isAutoLoans, dataType: DataType.Boolean, desc: 'Rate flag; 1 for fixed rate mortgage and 0 for adjustable rate mortgage', example: '0', type: 'boolean', required: 'Required', validations: { required } },

    { binding: 'servicingAssumptionSet', header: 'Servicing Assumption Set', isRequired: true, visible: isMsr && !isHedge, desc: 'User defined assumption set name used when setting servicing assumptions across the board', example: 'GSE', type: 'string', required: 'Required if not setting at the loan level' },
    { binding: 'quoteType', header: 'Quote Type', isRequired: true, visible: analysis.runType == runTypes.riskValDynamics, desc: 'Valuation method: OAS, Price, Yield, Z-Spread, Coupon Spread', example: 'OAS', type: 'string', required: 'Required if using differing quote types at the loan level' },
    { binding: 'quote', header: 'Quote', isRequired: true, visible: analysis.runType == runTypes.riskValDynamics, dataType: DataType.Number, desc: 'Value corresponding to the valuation method specified in column 3, can be the same for all loans or different', example: '250.0', type: 'double', required: 'Required if using differing quote types at the loan level' },
    { binding: 'originalTerm', header: 'Original Term', isRequired: true, dataType: DataType.Number, desc: 'Original Term in months', example: '60', type: 'integer', validations: { required, integer, between: between(0, 500) } },
    { binding: 'age', header: 'Age', isRequired: true, dataType: DataType.Number, desc: 'Current age of the loan in months', example: '12', type: 'integer', validations: { required, integer, between: between(0, 500) } },
    { binding: 'remainingTerm', header: 'Remaining Term', isRequired: true, dataType: DataType.Number, desc: 'Remaining term in months', example: '48', type: 'integer', validations: { required, integer, between: between(0, 500) } },
    { binding: 'firstForecastDate', header: 'First Forecast Date', isRequired: false, visible: isModelOnly , dataType: DataType.Date = moment().startOf('day'), desc: 'The month/year to begin forecasting', example: '4/15/2021', type: 'date', validations: { Date, between: between(moment("11/01/1991"), moment("12/31/2100"))} },

    { binding: 'origRate', header: 'Orig Rate', isRequired: false, dataType: DataType.Number, desc: 'Gross mortgage rate at origination in percent', example: '5.20', type: 'double', required: 'Suggested', validations: { decimal, between: between(0, 100) } },
    { binding: 'currentRate', header: 'Current Rate', isRequired: false, dataType: DataType.Number, visible: !isAutoLoans, desc: 'Gross current mortgage rate in percent', example: '3.05', type: 'double', required: 'Suggested', validations: { decimal, between: between(1, 100) } },
    { binding: 'origLoanSize', header: 'Orig Loan Size', isRequired: false, dataType: DataType.Number, desc: 'Loan balance at origination', example: '20000.00', type: 'double', required: 'Suggested', validations: { decimal, between: between(0, 1000000000000) } },
    { binding: 'currentLoanSize', header: 'Current Loan Size', isRequired: false, dataType: DataType.Number, desc: 'Current balance in dollars', example: '15000.00', type: 'double', required: 'Suggested', validations: { decimal, between: between(0, 1000000000000) } },
    { binding: 'origLtv', header: 'Orig LTV', isRequired: false, dataType: DataType.Number, desc: 'Loan-to-Value at origination in percent', example: '95.0', type: 'double', required: 'Suggested', validations: { decimal, between: between(-1, 300) } },
    { binding: 'origTotalLtv', header: 'Orig Total LTV', isRequired: false, dataType: DataType.Number, visible: !isAutoLoans && !isMultiFamily, desc: 'Original combined LTV in percent', example: '95.0', type: 'double', required: 'Suggested', validations: { decimal, between: between(-1, 300) } },
    { binding: 'currentLtv', header: 'Current LTV', isRequired: false, visible: !isAutoLoans && !isMultiFamily, dataType: DataType.Number, desc: 'Current LTV in percent, will be calculated internally if left empty', example: '80.0', type: 'double', required: 'Optional', validations: { decimal, between: between(-1, 300) }  },
    { binding: 'currentTotalLtv', header: 'Current Total LTV', isRequired: false, visible: !isAutoLoans && !isMultiFamily, dataType: DataType.Number, desc: 'Current combined LTV in percent', example: '100.0', type: 'double', required: 'Optional', validations: { decimal, between: between(-1, 300) }  },
    { binding: 'originalCreditScore', header: 'Original Credit Score', isRequired: false, visible: !isMultiFamily, dataType: DataType.Number, desc: 'Credit score at origination', example: '725.0', type: 'double', required: 'Suggested', validations: { decimal, between: between(0, 1000) }  },
    { binding: 'originalDTI', header: 'Original DTI', isRequired: false, visible: !isAutoLoans && !isMultiFamily && isLdm3, dataType: DataType.Number, desc: 'Debt-to-income ratio based the sum of the borrowers monthly debt payments, including monthly housing expenses divided by the total monthly income used to underwrite the loan', example: '35.0', type: 'double', required: 'Recommended', validations: { decimal, between: between(0, 200) } },

    // MultiFamily Model Fields
    { binding: 'prepayPenaltyStructure', header: 'Prepay Penalty Structure', isRequired: false, visible: isMultiFamily, desc: 'The origination prepayment penalty structure on a loan: POINTS_ONLY, LOCKOUT_ONLY, YIELD_MAINTENANCE_ONLY, LOCKOUT_TO_POINTS, YIELD_MAINTENANCE_TO_POINTS, LOCKOUT_TO_YIELD_MAINTENANCE, UNKNOWN_TO_POINTS', example: 'POINTS_ONLY', type: 'string', required: 'Optional' },
    { binding: 'ppMonths', header: 'Total Penalty Months', isRequired: false, visible: isMultiFamily, dataType: DataType.Number, desc: 'Number of months from origination the borrower is subject to a prepay penalty', example: '36', type: 'integer', required: 'Optional', validations: { integer, between: between(0, 1000) }  },
    { binding: 'initialPenaltyMonths', header: 'Initial Penalty Months', isRequired: false, visible: isMultiFamily, dataType: DataType.Number, desc: 'The number of months from origination that a loan is in its initial penalty state', example: '36', type: 'string', required: 'Optional' },
    { binding: 'penaltyPointSetName', header: 'Custom Points', isRequired: false, visible: isMultiFamily, desc: 'User defined custom points name used when a points-based penalty structure is selected', example: 'MyPoints', type: 'int', required: 'Optional' },

    // Auto Model Fields
    { binding: 'monthsDelinquent', header: 'Months Delinquent', isRequired: false, visible: isAutoLoans, desc: 'Number of missed payments (0=Current, 1=D30)', example: '0', type: 'integer', required: 'Suggested', validations: { integer, between: between(0, 1000) } },
    { binding: 'cumMonthsDelinquent', header: 'Cum Months Delq', isRequired: false, visible: isAutoLoans, desc: 'Cumulative months ever delinquent since origination', example: '2', type: 'integer', required: 'Optional', validations: { integer, between: between(0, 360) } },
    { binding: 'everModified', header: 'Ever Modified', isRequired: false, visible: isAutoLoans, dataType: DataType.Boolean, desc: 'Indicates if the loan has ever been modified or extended (1=Yes, 0=No)', example: '0', type: 'boolean', required: 'Optional' },

    // For loan level delinquency
    { binding: 'delinquency', header: 'Delinquency', isRequired: false, visible: !isPools && !isAutoLoans && !isMultiFamily, desc: 'Delinquency status: C = Current; F = Foreclosure; R = REO; T= Terminated; 1 = 30 Days Delinquent; 2 = 60 Days; 3 = 90 Days; 4 = 120 Days; 5 = 150 Days; 6 = 180 Days Delinquent', example: 'C', type: 'string', required: 'Suggested'  },
    { binding: 'monthsDelinquent', header: 'Months Delinquent', isRequired: false, visible: analysis.runType == runTypes.loanDynamics && !isPools && !isAutoLoans && !isMultiFamily, desc: 'Number of missed payments, set for loans in place/in addition to DelinquencyStatus', example: '2', type: 'integer', required: 'Suggested', validations: { integer, between: between(0, 1000) }  },

    // For pool level delinquency
    { binding: 'current', header: 'Current', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of pool current on payment', example: '40.0', type: 'double', required: 'Suggested'  },
    { binding: 'del30Days', header: 'Del 30 Days', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of pool 30 days delinquent', example: '45.0', type: 'double', required: 'Suggested'  },
    { binding: 'del60Days', header: 'Del 60 Days', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of pool 60 days delinquent', example: '25.0', type: 'double', required: 'Suggested'  },
    { binding: 'del90Days', header: 'Del 90 Days', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of pool 90 days delinquent', example: '35.0', type: 'double', required: 'Suggested'  },
    { binding: 'del120Days', header: 'Del 120 Days', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of pool 120 days delinquent', example: '40.0', type: 'double', required: 'Suggested'  },
    { binding: 'del150Days', header: 'Del 150 Days', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of pool 150 days delinquent', example: '45.0', type: 'double', required: 'Suggested'  },
    { binding: 'del180Days', header: 'Del 180 Days', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of pool 180 days delinquent', example: '25.0', type: 'double', required: 'Suggested'  },
    { binding: 'foreclosure', header: 'Foreclosure', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of pool in foreclosure', example: '35.0', type: 'double', required: 'Suggested'  },
    { binding: 'reo', header: 'REO', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of pool real estate owned', example: '40.0', type: 'double', required: 'Suggested'  },
    { binding: 'terminated', header: 'Terminated', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of pool terminated', example: '15.0', type: 'double', required: 'Suggested'  },

    { binding: 'prevDel', header: 'Prev Delinq', isRequired: false, visible: !isPools && !isAutoLoans && !isMultiFamily && !isPools, dataType: DataType.Boolean, desc: 'Indicator of whether the loan has been delinquent in the past', example: '1', type: 'boolean', required: 'Suggested' },
    { binding: 'monthsSinceDelinq', header: 'Months Since Delinq', isRequired: false, visible: !isAutoLoans && !isMultiFamily, dataType: DataType.Number, desc: 'Number of months since the last time a loan was delinquent', example: '0', type: 'integer', required: 'Suggested', validations: { integer, between: between(0, 1000) } },

    // ARM attributes
    { binding: 'firstResetAge', header: 'First Reset Age', isRequired: false, visible: !isAutoLoans, dataType: DataType.Number, desc: 'For ARMs: Age when mortgage rate first resets', example: '60', type: 'integer', required: 'Suggested for ARMs', validations: { integer, between: between(1, 998) }  },
    { binding: 'monthsBetweenReset', header: 'Months Between Reset', isRequired: false, visible: !isAutoLoans, dataType: DataType.Number, desc: 'For ARMs: Number of months between each reset', example: '12', type: 'integer', required: 'Suggested for ARMs', validations: { integer, between: between(1, 998) }  },
    { binding: 'index', header: 'Index', isRequired: true, visible: !isAutoLoans, desc: 'Rate Index Code For Adjustable Rate Mortgages: SOFR, SOFR30A, SOFR90A, SOFR180A, MTA_12, PAR_TSY_1MO, PAR_TSY_3MO, PAR_TSY_6MO, PAR_TSY_1YR, PAR_TSY_2YR, PAR_TSY_3YR, PAR_TSY_5YR, PAR_TSY_7YR, PAR_TSY_10YR, PAR_TSY_30YR, PAR_LIBOR_1M, PAR_LIBOR_3M, PAR_LIBOR_6M, PAR_LIBOR_1YR, PAR_PRIME, COF, _FED_DISCOUNT, FED_FUND', example: 'PAR_TSY_1YR', type: 'string', required: 'Required for ARMs', validations: { requiredIfArm } },
    { binding: 'grossMargin', header: 'Gross Margin', isRequired: false, visible: !isAutoLoans, dataType: DataType.Number, desc: 'For ARMs: Gross margin of loan, in %', example: '2.7', type: 'double', required: 'Suggested for ARMs'   },
    { binding: 'lifeCap', header: 'Life Cap', isRequired: false, visible: !isAutoLoans, dataType: DataType.Number, desc: 'For ARMs: Lifetime Maximum Mortgage Rate of ARM, in (%)', example: '9.825', type: 'double', required: 'Suggested for ARMs'   },
    { binding: 'lifeFloor', header: 'Life Floor', isRequired: false, visible: !isAutoLoans, dataType: DataType.Number, desc: 'For ARMs: Lifetime Minimum Mortgage Rate of ARM, in (%)', example: '2.7', type: 'double', required: 'Suggested for ARMs'   },
    { binding: 'firstResetCap', header: 'First Reset Cap', isRequired: false, visible: !isAutoLoans, dataType: DataType.Number, desc: 'For ARMs: Maximum allowable rate change at first rate adjustment, in (%)', example: '2.0', type: 'double', required: 'Suggested for ARMs' , validations: { between: between(0.01, 100) }  },
    { binding: 'firstResetFloor', header: 'First Reset Floor', isRequired: false, visible: analysis.runType == runTypes.loanDynamics && !isAutoLoans, dataType: DataType.Number, desc: 'For ARMs: Maximum allowable rate decrease at first rate adjustment, in (%)', example: '2.0', type: 'double', required: 'Suggested for ARMs' , validations: { between: between(0.01, 100) } },
    { binding: 'periodicCap', header: 'Periodic Cap', isRequired: false, visible: !isAutoLoans, dataType: DataType.Number, desc: 'For ARMs: Maximum allowable subsequent rate increases, in (%)', example: '2.0', type: 'double', required: 'Suggested for ARMs'   },
    { binding: 'periodicFloor', header: 'Periodic Floor', isRequired: false, visible: !isAutoLoans, dataType: DataType.Number, desc: 'For ARMs: Maximum allowable subsequent rate decreases, in (%)', example: '2.0', type: 'double', required: 'Suggested for ARMs'   },
    { binding: 'paymentChangeCap', header: 'Payment Change Cap', isRequired: false, visible: !isAutoLoans && !isMultiFamily, dataType: DataType.Number, desc: 'For ARMs: Maximum % payment increase/decrease at each reset (%)', example: '1.0', type: 'double', required: 'Suggested for ARMs'   },
    { binding: 'paymentResetPeriod', header: 'Payment Reset Period', isRequired: false, visible: !isAutoLoans && !isMultiFamily, dataType: DataType.Number, desc: 'For ARMs: the number of months between payment resets', example: '12', type: 'integer', required: 'Suggested for ARMs', validations: { integer, between: between(1, 998) } },
    { binding: 'paymentRecastPeriod', header: 'Payment Recast Period', isRequired: false, visible: !isAutoLoans && !isMultiFamily, dataType: DataType.Number, desc: 'For ARMs: the number of months between payment recasts', example: '12', type: 'integer', required: 'Suggested for ARMs', validations: { integer, between: between(1, 998) }  },
    { binding: 'currentMinimumPayment', header: 'Current Minimum Payment', isRequired: false, visible: !isAutoLoans && !isMultiFamily, dataType: DataType.Number, desc: 'For Option ARMs: Current minimum payment ($)', example: '1791.98', type: 'double', required: 'Suggested for ARMs'   },
    { binding: 'maxNegamPercent', header: 'Max Negam Percent', isRequired: false, visible: !isAutoLoans && !isMultiFamily, dataType: DataType.Number, desc: 'For ARMs: maximum percentage of original balance that loan can negatively amortize to; for example 121 would be 121% of the original balance', example: '121.0', type: 'double', required: 'Suggested for ARMs'   },
    
    { binding: 'lienPosition', header: 'Lien Position', isRequired: false, visible: !isAutoLoans && !isMultiFamily, dataType: DataType.Number, desc: 'Lien position, 1 is for the first lien', example: '1', type: 'integer', required: 'Suggested', validations: { integer, between: between(0, 100) } },
    { binding: 'balloonMonths', header: 'Balloon Months', isRequired: false, visible: !isAutoLoans, dataType: DataType.Number, desc: 'Months from origination that balloon payment is scheduled', example: '120', type: 'integer', required: 'Suggested', validations: { decimal, between: between(0, 1000) } },
    { binding: 'ppMonths', header: 'PP Months', isRequired: false, visible: !isAutoLoans && !isMultiFamily, dataType: DataType.Number, desc: 'Number of months from origination the borrower is subject to a prepay penalty', example: '36', type: 'integer', required: 'Suggested', validations: { integer, between: between(0, 1000) }  },
    { binding: 'ioMonths', header: 'IO Months', isRequired: false, visible: !isAutoLoans, dataType: DataType.Number, desc: 'Number of months from origination the borrower can make interest-only', example: '0', type: 'integer', required: 'Suggested', validations: { integer, between: between(0, 1000) }  },
    { binding: 'numUnits', header: 'Num Units', isRequired: false, visible: !isAutoLoans && !isMultiFamily, dataType: DataType.Number, desc: 'Number of units in each residence', example: '1', type: 'integer', required: 'Suggested', validations: {integer, between: between(0, 1e+06)} },
    
    // For loan level documentation
    { binding: 'documentation', header: 'Documentation', isRequired: false, visible: !isAutoLoans && !isPools && !isMultiFamily, desc: 'F = Full; L = Limited; 0 = None', example: 'L', type: 'string', required: 'Suggested'  },

    // For pool level documentation
    { binding: 'docFull', header: 'Doc Full', isRequired: false, visible: !isAutoLoans && isPools, dataType: DataType.Number, desc: 'Percent of pool with full documentation', example: '75.0', type: 'double', required: 'Suggested'  },
    { binding: 'docLimited', header: 'Doc Limited', isRequired: false, visible: !isAutoLoans && isPools, dataType: DataType.Number, desc: 'Percent of pool with limited documentation', example: '20.0', type: 'double', required: 'Suggested'  },
    { binding: 'docNone', header: 'Doc None', isRequired: false, visible: !isAutoLoans && isPools, dataType: DataType.Number, desc: 'Percent of pool with no documentation', example: '5.0', type: 'double', required: 'Suggested'  },
    
    // For loan level property type
    { binding: 'propertyType', header: 'Property Type', isRequired: false, visible: !isAutoLoans && !isPools && !isMultiFamily, desc: 'SFR = Single Family; MFR = Single Family where number of units >= 2; Condo = Condo; Coop = Coop; PUD = PUD; MH = Manufactured_Housing', example: 'SFR', type: 'string', required: 'Suggested' },
    
    // For pool level property type
    { binding: 'singleFamily', header: 'Single Family', isRequired: false, visible: !isAutoLoans && isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is Single Family', example: '25.0', type: 'double', required: 'Suggested'  },
    { binding: 'condo', header: 'Condo', isRequired: false, visible: !isAutoLoans && isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is Condo', example: '25.0', type: 'double', required: 'Suggested'  },
    { binding: 'manufacturedHousing', header: 'Manufactured Housing', isRequired: false, visible: !isAutoLoans && isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is Manufactured Housing', example: '20.0', type: 'double', required: 'Suggested'  },
    { binding: 'coop', header: 'Coop', isRequired: false, visible: !isAutoLoans && isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is Co-Op', example: '15.0', type: 'double', required: 'Suggested'  },
    { binding: 'pud', header: 'PUD', isRequired: false, visible: !isAutoLoans && isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is Planned Urban Development', example: '10.0', type: 'double', required: 'Suggested'  },
    
    // For loan level loan purpose
    { binding: 'loanPurpose', header: 'Loan Purpose', isRequired: false, visible: !isAutoLoans && !isPools && !isMultiFamily, desc: 'P = Purchase; E = Cash out refinance; R = Rate driven refinance; C = Refi_Construction', example: 'P', type: 'string', required: 'Suggested' },
    
    // For pool level loan purpose
    { binding: 'purchase', header: 'Purchase', isRequired: false, visible: !isAutoLoans && isPools, dataType: DataType.Number, desc: 'Percentage of balance used to Purchase ', example: '50.0', type: 'double', required: 'Suggested'  },
    { binding: 'refiEquity', header: 'Refi Equity', isRequired: false, visible: !isAutoLoans && isPools, dataType: DataType.Number, desc: 'Percentage of balance Cash-out Refi ', example: '50.0', type: 'double', required: 'Suggested'  },
    { binding: 'refiRate', header: 'Refi Rate', isRequired: false, visible: !isAutoLoans && isPools, dataType: DataType.Number, desc: 'Percentage of balance Refi for rate', example: '50.0', type: 'double', required: 'Suggested'  },
    { binding: 'refiConstruction', header: 'Refi Construction', isRequired: false, visible: !isAutoLoans && isPools, dataType: DataType.Number, desc: 'Percentage of balance Refi construction', example: '50.0', type: 'double', required: 'Suggested'  },
    
    // For loan level occupancy
    { binding: 'occupancy', header: 'Occupancy', isRequired: false, visible: !isAutoLoans && !isPools && !isMultiFamily, desc: 'O = Owner; S = Second_Home; I = Investor', example: 'O', type: 'string', required: 'Suggested' },

    // For pool level occupancy
    { binding: 'owner', header: 'Owner', isRequired: false, visible: !isAutoLoans && isPools, dataType: DataType.Number, desc: 'Percentage of balance owner occupied property', example: '60.0', type: 'double', required: 'Suggested'  },
    { binding: 'secondHome', header: 'Second Home', isRequired: false, visible: !isAutoLoans && isPools, dataType: DataType.Number, desc: 'Percentage of balance: second home', example: '30.0', type: 'double', required: 'Suggested'  },
    { binding: 'investor', header: 'Investor', isRequired: false, visible: !isAutoLoans && isPools, dataType: DataType.Number, desc: 'Percentage of balance investor home', example: '10.0', type: 'double', required: 'Suggested'  },
    
    // Loan level LDM 3.0 attributes
    { binding: 'originationChannel', header: 'Origination Channel', isRequired: false, visible: !isPools && !isAutoLoans && !isMultiFamily && isLdm3, desc: 'Indicates whether a broker or correspondent was involved in the origination of the mortgage loan (R for retail; B for broker; C for correspondent).', example: 'R', type: 'string', required: 'Recommended' },
    { binding: 'multipleBorrowers', header: 'Multiple Borrowers', isRequired: false, visible: !isPools && !isAutoLoans && !isMultiFamily && isLdm3, desc: 'Indicates if there are multiple borrowers obligated to repay the mortgage note secured by the mortgaged property; 0=No, 1=Yes', example: '0', type: 'boolean', required: 'Recommended' },
    { binding: 'firstTimeHomebuyer', header: 'First Time Homebuyer', isRequired: false, visible: !isPools && !isAutoLoans && !isMultiFamily && isLdm3, desc: 'Indicates borrower(s) are purchasing the home for the first time; 0=No, 1=Yes', example: '0', type: 'boolean', required: 'Recommended' },
    
    // Pool level LDM 3.0 attributes
    { binding: 'retail', header: 'Retail Origination', isRequired: false, visible: !isAutoLoans && isPools && isLdm3, dataType: DataType.Number, desc: 'Percentage of pool loans that are originated, underwritten and funded by a lender', example: '25.0', type: 'double', required: 'Recommended' },
    { binding: 'broker', header: 'Broker Origination', isRequired: false, visible: !isAutoLoans && isPools && isLdm3, dataType: DataType.Number, desc: 'Percentage of pool loans originated from a person or entity that specializes in loan originations, receiving a commission to match borrowers and lenders', example: '25.0', type: 'double', required: 'Recommended' },
    { binding: 'correspondent', header: 'Correspondent Origination', isRequired: false, visible: !isAutoLoans && isPools && isLdm3, dataType: DataType.Number, desc: 'Percentage of pool loans originated from a person or entity that typically sells the mortgages it originates to other lenders', example: '25.0', type: 'double', required: 'Recommended' },
    { binding: 'multipleBorrowersYes', header: 'Multiple Borrowers (Yes)', isRequired: false, visible: !isAutoLoans && isPools && isLdm3, dataType: DataType.Number, desc: 'Percent of pool loans that have multiple borrowers', example: '25.0', type: 'double', required: 'Recommended' },
    { binding: 'multipleBorrowersNo', header: 'Multiple Borrowers (No)', isRequired: false, visible: !isAutoLoans && isPools && isLdm3, dataType: DataType.Number, desc: 'Percent of pool loans that do NOT have multiple borrowers', example: '25.0', type: 'double', required: 'Recommended' },
    { binding: 'firstTimeHomebuyerYes', header: 'First Time Homebuyer (Yes)', isRequired: false, visible: !isAutoLoans && isPools && isLdm3, dataType: DataType.Number, desc: 'Percent of pool loans that are first time homebuyers', example: '25.0', type: 'double', required: 'Recommended' },
    { binding: 'firstTimeHomebuyerNo', header: 'First Time Homebuyer (No)', isRequired: false, visible: !isAutoLoans && isPools && isLdm3, dataType: DataType.Number, desc: 'Percent of pool loans that are NOT first time homebuyers', example: '25.0', type: 'double', required: 'Recommended' },
    
    { binding: 'miPremium', header: 'MI Premium', isRequired: false, visible: !isMsr && !isAutoLoans && !isMultiFamily, dataType: DataType.Number, desc: 'The premium paid by the borrower for MI. A value of 1 implies a 1% annual premium and a value of 0 implies no MI annual premium.', example: '1.0', type: 'double', required: 'Optional'  },
    { binding: 'miPercent', header: 'MI Percent', isRequired: false, visible: !isMsr && !isAutoLoans && !isMultiFamily, dataType: DataType.Number, desc: 'Percentage of original balance covered by MI. This field is used to calculate severity. Severity for loans covered by MI are capped at this value. If set to 0 or -1, other MI fields are not used.', example: '20.0', type: 'double', required: 'Optional'  },
    { binding: 'miCutoff', header: 'MI Cutoff', isRequired: false, visible: !isMsr && !isAutoLoans && !isMultiFamily, dataType: DataType.Number, desc: 'When mortgage insurance (MI) is set, MI_Cutoff represents the LTV after which MI is canceled. If set to 0, MI is never canceled. LTV is updated monthly as the loan amortizes but does not reflect home price appreciation.', example: '79.0', type: 'double', required: 'Optional'  },
    
    // Trended Credit
    { binding: 'trendedCredit', header: 'Trended Credit', isRequired: false, visible: !isAutoLoans && !isMultiFamily && !isPools && hasTrendedCredit, dataType: DataType.String, desc: 'Indicates the borrower is a transactor or revolver for Trended Credit; T = Transactor, R = Revolver', type: 'string', required: 'Optional' },
    { binding: 'transactor', header: 'Transactor', isRequired: false, visible: !isAutoLoans && !isMultiFamily && isPools && hasTrendedCredit, dataType: DataType.Number, desc: 'Percent of pool that is a transactor for Trended Credit', type: 'double', required: 'Optional'  },
    { binding: 'revolver', header: 'Revolver', isRequired: false, visible: !isAutoLoans && !isMultiFamily && isPools && hasTrendedCredit, dataType: DataType.Number, desc: 'Percent of pool that is a revolver for Trended Credit', type: 'double', required: 'Optional'  },

    // For loan level geography
    { binding: 'state', header: 'State', isRequired: false, visible: !isPools, desc: 'The state in which the property is located', example: 'NC', type: 'string', required: 'Suggested' },
    { binding: 'zip', header: 'Zip', isRequired: false, visible: !isPools && !isAutoLoans && !isMultiFamily, desc: 'Zip code', example: '10023', type: 'string', required: 'Suggested', validations: { integer, minLength: minLength(5), maxLength: maxLength(5), between: between(501, 99950) } },
    { binding: 'cbsa', header: 'CBSA', isRequired: false, visible: !isPools && !isAutoLoans && !isMultiFamily && analysis.runType == runTypes.loanDynamics, desc: 'CBSA code where the property is located.; takes precendence over zip code', example: '10580', type: 'string', required: 'Suggested' , validations: { integer, between: between(10000,60000) }},
    
    // For pool level geography
    { binding: 'stateAK', header: 'AK', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of AK', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateAL', header: 'AL', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of AL', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateAR', header: 'AR', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of AR', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateAZ', header: 'AZ', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of CZ', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateCA', header: 'CA', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of CA', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateCO', header: 'CO', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of CO', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateCT', header: 'CT', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of CT', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateDC', header: 'DC', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in Washington DC', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateDE', header: 'DE', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of DE', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateFL', header: 'FL', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of FL', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateGA', header: 'GA', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of GA', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateHI', header: 'HI', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of HI', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateIA', header: 'IA', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of IA', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateID', header: 'ID', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of ID', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateIL', header: 'IL', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of IL', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateIN', header: 'IN', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of IN', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateKS', header: 'KS', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of KS', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateKY', header: 'KY', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of KY', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateLA', header: 'LA', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of LA', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateMA', header: 'MA', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of MA', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateMD', header: 'MD', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of MD', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateME', header: 'ME', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of ME', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateMI', header: 'MI', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of MI', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateMN', header: 'MN', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of MN', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateMO', header: 'MO', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of MO', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateMS', header: 'MS', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of MS', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateMT', header: 'MT', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of MT', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateNC', header: 'NC', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of NC', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateND', header: 'ND', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of ND', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateNE', header: 'NE', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of NE', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateNH', header: 'NH', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of NH', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateNJ', header: 'NJ', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of NJ', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateNM', header: 'NM', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of NM', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateNV', header: 'NV', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of NV', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateNY', header: 'NY', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of NY', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateOH', header: 'OH', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of OH', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateOK', header: 'OK', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of OK', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateOR', header: 'OR', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of OR', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'statePA', header: 'PA', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of PA', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateRI', header: 'RI', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of RI', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateSC', header: 'SC', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of SC', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateSD', header: 'SD', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of SD', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateTN', header: 'TN', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of TN', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateTX', header: 'TX', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of TX', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateUT', header: 'UT', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of UT', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateVA', header: 'VA', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of VA', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateVT', header: 'VT', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of VT', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateWA', header: 'WA', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of WA', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateWI', header: 'WI', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of WI', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateWV', header: 'WV', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of WV', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateWY', header: 'WY', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in the state of WY', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'statePR', header: 'PR', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in PR', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateGU', header: 'GU', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in GU', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateVI', header: 'VI', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in VI', example: '10.0', type: 'double', required: 'Suggested'  },
    { binding: 'stateUS', header: 'US', isRequired: false, visible: isPools, dataType: DataType.Number, desc: 'Percentage of Pool that is in US, but the state is unknown', example: '10.0', type: 'double', required: 'Suggested'  },
    
    // Auto Model Fields
    { binding: 'multipleBorrowers', header: 'Multiple Borrowers', isRequired: false, visible: isAutoLoans, dataType: DataType.Boolean, desc: 'Indicates if there are multiple borrowers obligated to repay the loan (1=Yes, 0=No)', example: '0', type: 'boolean', required: 'Suggested' },
    { binding: 'vehicleNew', header: 'Vehicle New', isRequired: false, visible: isAutoLoans, dataType: DataType.Boolean, desc: 'Indicates the vehicle purchased is new (1=New, 0=Used)', example: '0', type: 'boolean', required: 'Suggested' },
    { binding: 'vehicleType', header: 'Vehicle Type', isRequired: false, visible: isAutoLoans, desc: 'Vehicle Type (Car, Truck, SUV, Van, Unknown)', example: 'Truck', type: 'string', required: 'Suggested' },
    { binding: 'vehicleMake', header: 'Vehicle Make', isRequired: false, visible: isAutoLoans, desc: 'Vehicle Make (Honda, Toyota, Other)', example: 'Honda', type: 'string', required: 'Suggested' },
    { binding: 'vehicleModelYear', header: 'Vehicle Model Year', isRequired: false, visible: isAutoLoans, dataType: DataType.Number, desc: 'Vehicle model year', example: '2017', type: 'integer', required: 'Suggested', validations: { integer, between: between(1900,3000) } },
    { binding: 'nonPrimeOriginator', header: 'Non-prime Originator', isRequired: false, visible: isAutoLoans, dataType: DataType.Boolean, desc: 'Indicates the loan was originated by a non-prime originator (1=Yes, 0=No)', example: '0', type: 'boolean', required: 'Optional' },
    
    { binding: 'dealName', header: 'Deal Name', isRequired: false, visible: !isAutoLoans && !isMultiFamily, desc: 'User defined Deal name', example: 'DEAL1', type: 'string', required: 'Optional' },
    { binding: 'groupNumber', header: 'Group Number', isRequired: false, visible: !isAutoLoans && !isMultiFamily, desc: 'User defined Group Number', example: '2', type: 'integer', required: 'Optional', validations: { integer } },
    { binding: 'tuneString', header: 'TuneString', isRequired: false, visible: !isAutoLoans && !isMultiFamily && analysis.runType == runTypes.riskValDynamics, desc: 'Tunestring, dictates which set of model coefficients is used when calling the borrower behavior model. PRIME=Non-conforming but Prime characteristics; SUBPRIME=Non-conforming, but Subprime characteristics; FRD=Freddie Mac eligible; GNMA=Ginnie Mae eligible, FHA or VA; FNMA=Fannie Mae eligible; ALT_A=Non-conforming Alt-A underwriting', example: 'PRIME', type: 'string', required: 'Suggested' },
    
    { binding: 'positionBalance', header: 'Position Balance', isRequired: false, visible: (isHedge || isPools) && analysis.runType == runTypes.riskValDynamics, dataType: DataType.Number, desc: '', type: 'double', required: 'Suggested', validations: { decimal } },
    { binding: 'isConvertible', header: 'Is Convertible', isRequired: false, visible: !isAutoLoans && !isMultiFamily && analysis.runType == runTypes.riskValDynamics, dataType: DataType.Boolean, desc: 'Borrower can convert mortgage rate from variable to fixed: 0 = No; 1 = Yes', example: '1', type: 'boolean', required: 'Optional' },
    { binding: 'bankrupt', header: 'Bankrupt', isRequired: false, visible: !isAutoLoans && !isMultiFamily && analysis.runType == runTypes.riskValDynamics, dataType: DataType.Number, desc: 'Bankruptcy flag in %, for loan 100 or 0', example: '0', type: 'integer', required: 'Optional' },
    { binding: 'delinqMethod', header: 'Delinq Method', isRequired: false, visible: !isAutoLoans && !isMultiFamily && analysis.runType == runTypes.riskValDynamics, dataType: DataType.Number, desc: 'MBA = 0 or OTS = 1', example: '0', type: 'integer', required: 'Optional'  },
    { binding: 'monthsAtLoanMod', header: 'Months at Loan Mod', isRequired: false, visible: !isAutoLoans && !isMultiFamily && analysis.runType == runTypes.riskValDynamics, dataType: DataType.Number, desc: 'Age of a loan when last modified or set to 0', example: '0', type: 'integer', required: 'Optional'   },
    { binding: 'delinqHist', header: 'Delinq Hist', isRequired: false, visible: !isAutoLoans && !isMultiFamily && analysis.runType == runTypes.riskValDynamics, desc: 'A string of up to 60 characters that indicate past delinquency history of the loan. The first letter is the current status and each succeeding character maps to previous months. 0 = Current; 1 = 1 month delinquent; 2 = 2 months delinquent; 3 = 3 months delinquent; 4 = 4 or more months delinquent; 5 = Foreclosure; 6 = REO; 7 = loan did not existent in this period; X = data not available; D = 3 or more months delinquent (for cases where exact delinquency is unknown)', example: '4321000', type: 'string', required: 'Optional'  },
    { binding: 'servicingFee', header: 'Servicing Fee', isRequired: true, dataType: DataType.Number, visible: !isAutoLoans && !isMultiFamily && analysis.runType == runTypes.riskValDynamics, desc: 'Gross Servicing fee in percent, base servicing fee plus any excess servicing fee', example: '0.40', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'advancesS', header: 'Advances S', isRequired: true, visible: !isAutoLoans && !isMultiFamily && analysis.runType == runTypes.riskValDynamics, dataType: DataType.Boolean, desc: 'Advance missed payments for loans through foreclosure; 1=Yes 0=No', example: '1', type: 'boolean', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'advancesD', header: 'Advances D', isRequired: true, visible: !isAutoLoans && !isMultiFamily && analysis.runType == runTypes.riskValDynamics, dataType: DataType.Boolean, desc: 'Advance missed payments for loans that are 5 or less months delinquent; 1=Yes 0=No', example: '1', type: 'boolean', required: 'Required if ServicingAssumptionSet is not set' },
    
    // This field set automatically by web service based on asset type
    //{ binding: 'isMultiFamily', header: 'IsMultiFamily', isRequired: false, dataType: DataType.Number, type: 'boolean' },

    // MSR Fields
    { binding: 'msrAvgEscrowBalance', header: 'MSR Avg Escrow Balance', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Average escrow balance in dollars, used in computing interest earned and paid on escrow', example: '1500.00', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrEscrowInflation', header: 'MSR Escrow Inflation', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Annual rate of inflation on the escrow balance', example: '3.0', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrCostPerLoan', header: 'MSR Cost Per Loan', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Cost to service a performing loan', example: '100.00', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrCostPerD1', header: 'MSR Cost Per D1', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Cost to service 30 day delinquent loan', example: '500.00', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrCostPerD2', header: 'MSR Cost Per D2', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Cost to service 60 day delinquent loan', example: '750.00', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrCostPerD3', header: 'MSR Cost Per D3', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Cost to service 90 day delinquent loan', example: '1000.00', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrCostPerD4', header: 'MSR Cost Per D4', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Cost to service 120 day delinquent loan', example: '1200.00', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrCostPerD5', header: 'MSR Cost Per D5', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Cost to service 150 day delinquent loan', example: '1500.00', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrCostPerSeriouslyDelinq', header: 'MSR Cost Per Seriously Dlq', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Cost to service loan which is 180 or more days delinquent', example: '2000.00', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrCostPerForeclosure', header: 'MSR Cost Per Foreclosure', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Additional cost to service a loan in foreclosure before liquidating', example: '2000.00', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrCostInflation', header: 'MSR Cost Inflation', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Annual rate of inflation on the cost to service any loans', example: '3.0', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrIncomePerLoan', header: 'MSR Income Per Loan', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Annual ancillary income earned per loan in dollars', example: '10.00', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrIncomePerPayment', header: 'MSR Income Per Payment', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Monthly ancillary income earned per loan in percent', example: '2.00', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrScheduleFloatDays', header: 'MSR Schedule Float Days', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Number of days scheduled payments will generate float income', example: '14.0', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrPrepayFloatDays', header: 'MSR Prepay Float Days', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Number of days prepayments will generate float income', example: '14.0', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrEscrowRateEarned', header: 'MSR Escrow Rate Earned', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Annual earnings rate on average escrow balance, entered as either a spread over 1moLIBOR or a fixed rate, in percent', example: '0.50', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrEscrowRateEarnedFloat', header: 'MSR Escrow Rate Earned Float', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Escrow earnings rate type: 0 = Fixed 1 = Floating', example: '0', type: 'boolean', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrEscrowRatePaid', header: 'MSR Escrow Rate Paid', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Annual earnings paid on average escrow balance, entered as either a spread over 1moLIBOR or a fixed rate, in percent', example: '0.25', type: 'double', required: 'Required if ServicingAssumptionSet is not set'},
    { binding: 'msrEscrowRatePaidFloat', header: 'MSR EscrowRate Paid Float', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Escrow rate paid type: 0 = Fixed 1 = Floating', example: '1', type: 'boolean', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrPaymentFloatRateEarned', header: 'MSR Payment Float Rate Earned', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Annual earnings rate on float income entered as either a spread over 1moLIBOR or a fixed rate, in percent', example: '1.5', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrPaymentFloatRateFloat', header: 'MSR Payment Float Rate Float', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Float earnings rate type: 0 = Fixed 1 = Floating', example: '0', type: 'boolean', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrRecaptureMonths', header: 'MSR Recapture Months', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Number of months from MSR purchase that re-solicitation of the borrower results in recapture of the servicing fee from the seller', example: '6.0', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrRecapturePercent', header: 'MSR Recapture Percent', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Percentage of prepayments in which Servicer expects they will be able to enforce contractual recapture. Set to zero if Servicer does not enforce contractual recapture terms', example: '0.10', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrAdvancesFinanceRate', header: 'MSR Advances Finance Rate', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Annual rate paid on funds borrowed for advances entered as either a spread over 1moLIBOR or a fixed rate, in percent', example: '1.0', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrAdvancesFinanceRateFloat', header: 'MSR Advances Finance Rate Float', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Advance financing rate type: 0 = Fixed 1 = Floating', example: '0', type: 'boolean', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrAdvancesLeverage', header: 'MSR Advances Leverage', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Percentage of advances that are financed where 0.9 represents 90%', example: '0.90', type: 'double', required: 'Required if ServicingAssumptionSet is not set' },
    { binding: 'msrBaseServicing', header: 'MSR Base Servicing', isRequired: true, visible: isMsr && !isHedge, dataType: DataType.Number, desc: 'Used in computing any excess servicing fee when ServicingFee in field 59 exceeds the value in this field, .25 is 0.25%', example: '0.25', type: 'double', required: `Required if ServicingAssumptionSet is not set` }
  ];

  // Set default number format and validation
  cols.forEach(c => {
    if (c.dataType == DataType.Number && c.visible !== false) {
      c.format = c.format || 'g15';
      c.validations = c.validations || { decimal }
    }
  });

  return cols;
}