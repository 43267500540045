import ResultItem, { resultTypes } from './ResultItem'
import api from '../../api'
import { ldmVectors } from '../options/ldmVectors'
import ColorGenerator from './ColorGenerator'

// Create a module level instance so colors are consistent
let colorGen = new ColorGenerator();

async function getChartData (analysis, options) {
  let data = await api.odataQuery(analysis.id, this.collectionName, options.params);

  if (!data.value) {
    return [];
  }

  let createKey = (val) => `${val.scenarioName} ${val.loanId}`;
  let items = {};
  
  // Create an array for each vector
  data.value.forEach(r => {

    let item = items[createKey(r)];

    if (!item) {
      let key = createKey(r);
      item = { key, months: [] };
      items[key] = item;
    }

    for (const prop in r) {
      if (r.hasOwnProperty(prop)) {
        if (prop == "date") {
          item.months.push(r[prop]);
        } else {
          if (!item[prop]) {
            item[prop] = [];
          }

          item[prop].push(r[prop]);
        }
      }
    }
  });

  let chartData = [];

  for (const prop in items) {
    if (items.hasOwnProperty(prop)) {
      let item = items[prop];
      let arr = ldmVectors.map((a, i) => ({
        name: `${item.key} ${a.name}`,
        fieldName: a.name,
        key: a.prop,
        hoverlabel: {namelength :-1},
        line: { width: 2, color: colorGen.getColor(`${item.key}-${i}`) },
        x: item.months,
        y: item[a.prop],
        yaxis: a.defaultAxis == 'y2' ? 'y2' : null
      }))
  
      chartData.push(...arr);
    }
  }

  return chartData;
}

async function getTableData (analysis, options) {
  let data = await api.odataQuery(analysis.id, this.collectionName, options.params);

  if (!data.value) {
    return [];
  }

  return data;
}

async function getTableDataFromFiles (analysis, options) {
  let data = await api.getResults(analysis.id, this.collectionName, 1000);

  if (!data) {
    return [];
  }

  return data;
}

async function loadLdmReportColumns(analysisId, resultItem) {
  let cols = await api.getReportFields(resultItem.id, analysisId);
  resultItem.columns = () => 
    cols.filter(c => c.selected && c.enabled).map(c => ({ 
      header: c.label, 
      binding: c.name, 
      format: c.format, 
      class: c.class ? c.class : c.alignment == 'right' ? 'text-right' : '', 
      visible: c.visible === false ? false : true
    })).filter(c => c.visible);
    resultItem.columnsLoaded = true;
}

export default function createLdmResultItem(reportId, analysisId, displayType, cols, importResultsEnabled=true, positionFormat) {
  return new ResultItem ({
    id: reportId,
    name: `${positionFormat == 'Pool' ? 'Pool' : 'Loan'} Level Results`,
    loadColumns: async function (options) {
      await loadLdmReportColumns(analysisId, this)
    },
    resultType: resultTypes.portfolio,
    default: true,
    displayType: displayType || 'chart',
    reportLevel: displayType == 'chart' ? 0 : 1,
    collectionName: 'LdmResults',
    chartOptions: {
      title: `${positionFormat == 'Pool' ? 'Pool' : 'Loan'} Level Results`,
      fields: ldmVectors.filter(v => {
        return cols.find(c => c.name == v.prop)
      }).map(a => ({ 
        fieldName: a.name, 
        selected: a.prop == 'crr',
        defaultAxis: a.defaultAxis
      })),
      filters: [{
        name: 'Scenario',
        prop: 'scenarioName',
        getOptions(analysisId) {
          return api.getScenarios(analysisId)
        },
        preselect: 1
      },
      {
        name: `${positionFormat == 'Pool' ? 'Pool' : 'Loan'} ID`,
        prop: 'loanId',
        async getOptions(analysisId, query) {
          let data = await api.odataQuery(analysisId, "positions", {
            $top: 1000,
            $select: 'loanId',
            $filter: `contains(tolower(loanId), tolower('${query}')) and Run eq true`,
            $count: true
          });
          return data.value.map(x => x.loanId);
        },
        preselect: 1
      }]
    },
    getData: displayType == 'chart' ? getChartData : importResultsEnabled ? getTableData : getTableDataFromFiles
  });
};
