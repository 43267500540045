<template>
  <div>
    <collapsible-section
      section-title="Scenario Analysis"
      hide-toggle
    >
      <collapsible-options
        :value="enableScenarioGrid"
        @input="enableScenarioGridWithPrices($event)"
        title="20 Scenario Grid" 
        @change="setRepricing($event && scenarioGridRepricing, fileNames.scenarios, fileNames.scenarioGridRepricing)"
        collapsible
      >
        <b-btn
          class="mt-2 mr-2 mb-2"
          variant="secondary"
          @click="scenarioFileForImport=fileNames.scenarios;showImportScenarioDialog=true"
        >
          Import Scenarios
        </b-btn>

        <b-btn
          class="mt-2 mb-2"
          @click="scenarioFileForImport=fileNames.scenarios;showScenarios(fileNames.scenarios)"
        >
          View Scenarios
        </b-btn>

        <file-select-with-checkbox 
          label="Enable Forward Repricing"
          :value="scenarioGridRepricing"
          @input="$emit('update:scenarioGridRepricing', $event)"
          :file-name="fileNames.scenarioGridRepricing" 
          @change="setRepricing($event, fileNames.scenarios, fileNames.scenarioGridRepricing)"
        />
      </collapsible-options>
      
      <collapsible-options
        :value="enableStressTests"
        @input="enableStressTestWithPrices($event)"
        title="Stress Test and User-Defined Scenarios" 
        @change="setRepricing($event && stressTestRepricing, fileNames.stressScenarios, fileNames.stressTestRepricing)"
        collapsible
      >
        <b-btn
          class="mt-2 mr-2 mb-2"
          variant="secondary"
          @click="scenarioFileForImport=fileNames.stressScenarios;showImportScenarioDialog=true"
        >
          Import Scenarios
        </b-btn>

        <b-btn
          class="mt-2 mb-2"
          @click="scenarioFileForImport=fileNames.stressScenarios;showScenarios(fileNames.stressScenarios)"
        >
          View Scenarios
        </b-btn>

        <file-select-with-checkbox 
          label="Enable Forward Repricing"
          :value="stressTestRepricing"
          @input="$emit('update:stressTestRepricing', $event)"
          :file-name="fileNames.stressTestRepricing" 
          @change="setRepricing($event, fileNames.stressScenarios, fileNames.stressTestRepricing)"
        />
      </collapsible-options>
      
      <collapsible-options
        :value="enableCecl"
        @input="enableCeclWithPrices($event)"
        title="CECL" 
        @change="setRepricing($event && ceclRepricing, fileNames.ceclScenarios, fileNames.ceclRepricing)"
        collapsible
      >
        <b-btn
          class="mt-2 mr-2 mb-2"
          variant="secondary"
          @click="scenarioFileForImport=fileNames.ceclScenarios;showImportScenarioDialog=true"
        >
          Import Scenarios
        </b-btn>

        <b-btn
          class="mt-2 mb-2"
          @click="scenarioFileForImport=fileNames.ceclScenarios;showScenarios(fileNames.ceclScenarios)"
        >
          View Scenarios
        </b-btn>

        <file-select-with-checkbox 
          label="Enable Forward Repricing"
          :value="ceclRepricing"
          @input="$emit('update:ceclRepricing', $event)"
          :file-name="fileNames.ceclRepricing" 
          @change="setRepricing($event, fileNames.ceclScenarios, fileNames.ceclRepricing)"
        />
      </collapsible-options>

      <!-- This is not currently functional!!
        <collapsible-options
        v-if="enable('showB5')"
        v-model="calcs.showB5" 
        title="Show B5 measure (shortfall - premiums)" 
        :collapsible="true"
        @input="updateB5"
      >
        <div
          v-if="calcs.showB5"
          class="pl-4 pt-1 pb-3"
        >
          <b5-fields v-model="calcs.b5calcs" />
        </div>
      </collapsible-options> -->
    </collapsible-section>

    <scenario-viewer-dialog
      v-model="showScenarioDialog"
      :file-name="scenarioFileName"
      :repricing-file="repricingFile"
      :files-imported="filesImported"
    />

    <import-scenario-dialog 
      v-model="showImportScenarioDialog"
      :scenario-route="scenarioRoute"
      @imported="scenariosImported($event)"
    />

    <modal-dialog
      v-model="showImportComplete"
      title="File Import Complete"
      confirm-button-text="OK"
      ok-only
    >
      <span>
        Imported {{ filesImported }} file{{ filesImported > 1 ? 's' : '' }} successfully.
      </span>
    </modal-dialog>
  </div>
</template>

<script>
import ScenarioViewerDialog from './ScenarioViewerDialog.vue'
import ImportScenarioDialog from './ImportScenarioDialog.vue'
import CollapsibleOptions from './CollapsibleOptions.vue'
import FileSelectWithCheckbox from './FileSelectWithCheckbox.vue'
import CollapsibleSection from './CollapsibleSection.vue'
import ModalDialog from './ModalDialog.vue'
import { fileNames, getScenarioAnalysisType, setScenarioRepricingFile } from '../js/fileInfo'
import { mapMutations, mapState } from 'vuex'

export default {
  props: {
    enableScenarioGrid: { type: Boolean, required: true },
    scenarioGridRepricing: { type: Boolean, required: true },
    enableStressTests: { type: Boolean, required: true },
    stressTestRepricing: { type: Boolean, required: true },
    enableCecl: { type: Boolean, required: true },
    ceclRepricing: { type: Boolean, required: true }
  },
  data () {
    return {
      fileNames,
      showScenarioDialog: false,
      scenarioFileName: fileNames.scenarios,
      showImportScenarioDialog: false,
      showImportComplete: false,
      filesImported: 0,
      scenarioFileForImport: ''
    }
  },
  computed: {
    ...mapState(['activeAnalysis']),

    scenarioRoute () {
      return getScenarioAnalysisType(this.scenarioFileForImport);
    },

    repricingFile () {
      if (this.scenarioFileForImport == this.fileNames.scenarios && this.scenarioGridRepricing) {
        return this.fileNames.scenarioGridRepricing;
      } else if (this.scenarioFileForImport == this.fileNames.stressScenarios && this.stressTestRepricing) {
        return this.fileNames.stressTestRepricing;
      } else if (this.scenarioFileForImport == this.fileNames.ceclScenarios && this.ceclRepricing) {
        return this.fileNames.ceclRepricing;
      } else {
        return '';
      }
    }
  },
  methods: {
    ...mapMutations(["toggleFile"]),

    async setRepricing (enabled, scenarioTemplate, repricingFile) {
      await setScenarioRepricingFile(this.activeAnalysis.id, scenarioTemplate, enabled ? repricingFile : '');
    },
    showScenarios (fileName, filesImported = 0) {
      this.scenarioFileName = fileName;
      this.filesImported = filesImported;
      this.showScenarioDialog = true;
    },
    scenariosImported(evt) {
      if (evt.scenariosImported) {
        this.showScenarios(this.scenarioFileForImport, evt.fileCount);
      } else {
        this.filesImported = evt.fileCount;
        this.showImportComplete = true;
      }
    },
    enableScenarioGridWithPrices (enable) {
      this.$emit('update:enableScenarioGrid', enable);
      this.$emit('update:scenarioGridPrice', enable);
    },
    enableStressTestWithPrices (enable) {
      this.$emit('update:enableStressTests', enable);
      this.$emit('update:stressTestPrice', enable);
    },
    enableCeclWithPrices (enable) {
      this.$emit('update:enableCecl', enable);
      this.$emit('update:ceclPrice', enable);
    }
  },
  components: {
    ScenarioViewerDialog,
    CollapsibleOptions,
    CollapsibleSection,
    FileSelectWithCheckbox,
    ImportScenarioDialog,
    ModalDialog
  }
}
</script>

<style scoped>
h3 {
  font-size: 0.8em;
  font-weight: bold;
  margin: 15px 0 10px 0;
  padding-bottom: 3px;
  border-bottom: 1px solid #bfc0c9;
}
</style>

<style>
#ir-adjust-tooltip > .tooltip-inner {
  max-width: 400px;
}
</style>
