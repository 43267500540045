<template>
  <div class="flex-container grid-container">
    <loading-indicator v-if="gridLoading" />
    <div id="grid-portfolio" />
  </div>
</template>

<script>
import LoadingIndicator from './LoadingIndicator'
import PortfolioGrid from "../js/gridExtensions/PortfolioGrid";
import positionGridOptions from '../js/gridOptions/positionsGridOptions'
import { assetTypes } from '../js/options/assetType'
import { AllowSorting } from '@grapecity/wijmo.grid';
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: {
    portfolio: { type: Array, required: false }
  },
  data() {
    return {
      assetTypes,
      gridLoading: true,
      gridInit: false,
      gridCount: 0,
      reloadOnActivation: false,
      deactivated: false
    };
  },
  async mounted() {
    if (this.activeAnalysis.assetType == this.assetTypes.multiFamilyLoans) await this.getPenaltyPoints();
    await this.loadGrid();
  },
  computed: {
    ...mapState(['activeAnalysis', 'isValidating', 'authentication', 'appConfig', 'portfolioGridDirty', 'penaltyPointSets']),
    ...mapGetters(['isOnlyLicensingAgency', 'isOnlyLicensingNonAgency'])
  },
  methods: {
    ...mapMutations(['updateValidationStatus', 'setUnsaved', 'setAnalysisModified', 'setPortfolioGridDirty']),
    ...mapActions(['autoSaveItem', 'getPenaltyPoints']),

    async loadGrid () {
      const baseURL = `${this.authentication.server}/odata`;
      this.gridLoading = true;

      if (this.$options.grid) {
        this.$options.grid._grid.dispose();
        this.$options.grid._pager.dispose();
      }

      this.gridInit = false;

      let gridOptions = {
        baseURL,
        pager: '#grid-pager',
        collection: `Analyses(${this.activeAnalysis.id})/${positionGridOptions.entitySet}`,
        entityType: positionGridOptions.entityType(this.activeAnalysis),
        columns: positionGridOptions.columns(this.activeAnalysis),
        allowAddNew: !!positionGridOptions.editOptions.allowAddNew,
        allowDelete: !!positionGridOptions.editOptions.allowDelete,
        dataMaps: positionGridOptions.dataMaps(this.activeAnalysis, this.isOnlyLicensingAgency, this.isOnlyLicensingNonAgency, this.activeAnalysis.assetType == this.assetTypes.multiFamilyLoans, this.penaltyPointSets.filter(p => p.penaltyPointSetId != 'AD&Co Default')),
        keys: positionGridOptions.editOptions.keys,
        itemCreator: positionGridOptions.itemCreator(this.activeAnalysis),
        allowSorting: positionGridOptions.allowSort === false ? AllowSorting.None : AllowSorting.SingleColumn,
        defaultSort: positionGridOptions.defaultSort,
        pageSize: this.appConfig.portfolioPageSize,
        loading: () => { this.gridLoading = true; },
        loaded: () => { this.gridLoading = false; },
        copied: () => { this.$emit('can-paste', true) },
        dataFiltered:() => {
          if (this.changesPending()) {
            this.save();
            setTimeout(this.refresh, 500);
          }
        },
        collectionChanged: (count, filtered) => {
          this.gridCount = count;
          this.$emit('grid-update', {count, filtered});
          
          if (this.changesPending()) {
            this.setUnsaved();
            this.setAnalysisModified();
          }
        },
        canUndo: (enabled) => { this.$emit('can-undo', enabled) },
        canRedo: (enabled) => { this.$emit('can-redo', enabled) },
        paging: (navigate) => { this.$emit('grid-paging', { navigate }) }
      };

      this.$options.grid = new PortfolioGrid("#grid-portfolio", gridOptions, this.activeAnalysis.id);
      this.gridInit = true;
    },
    refresh () {
      this.$options.grid.refresh();
    },
    copy () {
      this.$options.grid.copy();
    },
    paste () {
      this.$options.grid.paste();
    },
    undo () {
      this.$options.grid.undo();
    },
    redo () {
      this.$options.grid.redo();
    },
    insertRow () {
      this.$options.grid.insertRow();
    },
    deleteSelectedRows () {
      this.$options.grid.deleteSelectedRows();
    },
    changesPending () {
      return this.$options.grid.changesPending();
    },
    clearPendingChanges () {
      this.$options.grid.clearPendingChanges();
    },
    validateGrid (validateCount = false) {
      let isValid = this.$options.grid.isValid() && (validateCount ? this.gridCount > 0 : true);

      this.updateValidationStatus({
          tab: 'Portfolio',
          item: 'portfolio-grid',
          isValid
        });

      return isValid;
    },
    save () {
      this.autoSaveItem({ itemName: 'PositionGrid', isValid: this.validateGrid(), onSave: () => this.$options.grid.save(), waitTime: 500 });
    },
    quickFilterRateType(filters) {
      this.$options.grid.quickFilterRateType(filters)
    },
    quickFilterIssuerType(filters) {
      this.$options.grid.quickFilterIssuerType(filters)
    },
    clearAllFilters() {
      this.$options.grid.clearAllFilters();
    }
  },
  async activated () {
    this.deactivated = false;
    if (this.portfolioGridDirty || this.reloadOnActivation) {
      if (this.$options.grid) await this.loadGrid();
      this.setPortfolioGridDirty(false);
      this.reloadOnActivation = false;
    }
  },
  deactivated () {
    this.deactivated = true;
  },
  watch: {
    isValidating (val) {
      if (val === true)
        this.validateGrid(true);
    },
    portfolioGridDirty: {
      async handler(val) {
        if (val && this.deactivated) {
          this.reloadOnActivation = true;
        } else if (val && !this.deactivated) {
          if (this.$options.grid) await this.loadGrid();
          this.setPortfolioGridDirty(false);
        }
      }
    },
  },
  components: {
    LoadingIndicator
  }
};
</script>

<style scoped>
#grid.wj-content {
  border-radius: 0 0 4px 4px;
  border-right: none;
  width: auto;
}
</style>
