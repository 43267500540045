import { render, staticRenderFns } from "./UsernameLookup.vue?vue&type=template&id=0047cf51&scoped=true"
import script from "./UsernameLookup.vue?vue&type=script&lang=js"
export * from "./UsernameLookup.vue?vue&type=script&lang=js"
import style0 from "./UsernameLookup.vue?vue&type=style&index=0&id=0047cf51&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0047cf51",
  null
  
)

export default component.exports