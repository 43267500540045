<template>
  <div>
    <collapsible-section
      :value="monteCarlo.enableMonteCarlo"
      @input="$emit('update-monte-carlo', { enableMonteCarlo: $event })"
      section-title="Value and Risk Metrics"
    >
      <collapsible-options 
        v-if="activeAnalysis.assetType == assetTypes.agencyPools"
        :value="payup"
        @input="$emit('update:payup', $event)"
        title="Payup"
        collapsible
      >
        <b-form-group
          label="Payup Horizon (months)"
          label-for="payup-horizon"
          :label-cols="5"
          v-if="payup"
        >
          <b-form-input
            id="payup-horizon"
            :value="v.payupHorizon.$model"
            @input="validateAndUpdate('update:payupHorizon', $event, v.payupHorizon)"
            class="field-sm"
            type="text"
            size="sm"
            autocomplete="off"
            :state="state(v.payupHorizon)"
          />
          <b-form-invalid-feedback :state="state(v.payupHorizon)">
            {{ validationMessage(v.payupHorizon) }}
          </b-form-invalid-feedback>
        </b-form-group>
      </collapsible-options>

      <collapsible-options 
        :value="monteCarlo.oas"
        @input="$emit('update-monte-carlo', { oas: $event })"
        title="OAS"
      />

      <collapsible-options 
        :value="forwardCurves"
        @input="$emit('update:forwardCurves', $event)"
        title="Forward Curve Analysis"
      />

      <collapsible-options
        :value="fieldConfigMetaData.enableInterestRateRisk"
        @input="$emit('update-field-config', { enableInterestRateRisk: $event })"
        title="Interest Rate Risk"
        collapsible
      >
        <span
          v-for="item in interestRateRiskTypes"
          :id="createId('span-' + item.name)"
          :key="item.name"
        >
          <b-form-checkbox
            :id="createId('checkbox-' + item.name)"
            :checked="monteCarlo[item.name]"
            @input="$emit('update-monte-carlo', getUpdateData(item.name, $event))"
            :disabled="!fieldConfigMetaData.enableInterestRateRisk"
          >{{ item.description }}</b-form-checkbox>
        </span>

        <file-select-with-checkbox 
          label="Yield Curve Duration"
          :value="monteCarlo.yieldCurves"
          @input="$emit('update-monte-carlo', { yieldCurves: $event })"
          :file-name="fileNames.yieldCurveShock"
          :allow-add-new="false"
          :allow-delete="false"
        />

        <b-form-checkbox
          :id="createId('checkbox-parShock')"
          :checked="monteCarlo.parShock"
          @input="$emit('update-monte-carlo', getUpdateData('parShock', $event))"
          :disabled="!fieldConfigMetaData.enableInterestRateRisk"
        >
          Parallel Shocks
        </b-form-checkbox>

        <b-btn
          v-if="monteCarlo.parShock"
          v-b-modal.shocks
          class="mt-2 mb-2"
        >
          Edit Shock Values
        </b-btn>
        <b-form-invalid-feedback :state="parallelShocksValid">
          Please add at least one parallel shock.
        </b-form-invalid-feedback>
      </collapsible-options>

      <collapsible-options
        :value="fieldConfigMetaData.enableModelRisk"
        @input="$emit('update-field-config', { enableModelRisk: $event })"
        title="Model Risk"
        :collapsible="true"
      >
        <span
          v-for="item in modelRiskTypes"
          :id="createId('span-' + item.name)"
          :key="item.name"
        >
          <b-form-checkbox
            :id="createId('checkbox-' + item.name)"
            :checked="monteCarlo[item.name]"
            @input="$emit('update-monte-carlo', getUpdateData(item.name, $event))"
            :disabled="!fieldConfigMetaData.enableModelRisk"
          >{{ item.description }}</b-form-checkbox>
        </span>
      </collapsible-options>

      <collapsible-options 
        :value="monteCarlo.hpiDuration"
        @input="$emit('update-monte-carlo', { hpiDuration: $event })"
        title="HPA Duration / Convexity"
        :collapsible="false"
      />

      <collapsible-options
        :value="enableCapitalCharge"
        @input="$emit('update-capital-charge', { enableCapitalCharge: $event })"
        title="Capital Charge"
        :collapsible="true"
      >
        <b-form
          v-if="enableCapitalCharge"
        >
          <b-form-group
            v-for="field in capitalChargeFields"
            :key="field.name"
            :label="field.label"
            :label-for="'cc-' + field.name"
            :label-cols="5"
          >
            <b-form-input
              :id="'cc-' + field.name"
              :value="v[field.name].$model"
              @input="validateAndUpdate('update-capital-charge', { [field.name] : toNumber($event) }, v[field.name])"
              type="text"
              class="field-sm"
              size="sm"
              :state="state(v[field.name])"
              autocomplete="off"
            />
            <b-form-invalid-feedback :state="state(v[field.name])">
              {{ validationMessage(v[field.name]) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form>
      </collapsible-options>

      <!-- THIS ENTIRE BLOCK IS COMMENTED BECAUSE IT WAS NEVER ENABLED IN THE UI.  
          KEEPING IT HERE BECAUSE IN THE FUTURE WE MAY REENABLE DURATION SHIFTS
          DURATIONS BELOW HAVE BEEN MOVED AS SUB OPTIONS TO OTHER CALC OPTIONS

          <div class="pl-4" v-if="calcs.showhomePriceRisk">
          <b-form-checkbox v-model="calcs.shortHpa">Short-term HPA Duration / Convexity</b-form-checkbox>
          <b-form-checkbox v-model="calcs.longHpa">Long-term HPA Duration / Convexity</b-form-checkbox>
          </div>

          Durations 
          <b-form-checkbox v-model="calcs.showdurations">Enable Durations</b-form-checkbox>
          <div class="pl-4">
          <span v-for="duration in durationtypes" :key="duration.name">
          <b-form-checkbox v-if="enable(`durations.${duration.name}`)" 
          v-model="calcs.durations[duration.name]">Show {{duration.description}}</b-form-checkbox>
          </span>
          <div class="pl-4" v-if="calcs.durations && calcs.durations.showycdur"><file-select :file-name="fileNames.yieldCurveShock"></file-select></div>
          </div>

          <span v-for="duration in durationshifttypes" :key="duration.name">
          <b-form-group
          :label="`Shift for ${durationShiftDescription(duration)}`"
          v-if="durationShiftEnable(duration)"
          label-for="duration-shifts"
          >
          <b-form-input
          type="text"
          id="duration-shifts"
          size="sm"
          v-model="calcs.durationshifts[duration.name]"
          style="max-width: 150px;"
          ></b-form-input>
          </b-form-group>
          </span>

          <b-form-checkbox v-if="enable('showyld')" v-model="calcs.showyld">Show Yields</b-form-checkbox>
          <b-form-checkbox v-if="enable('showzspread')" v-model="calcs.showzspread">Show Z-Spread</b-form-checkbox>

      -->

      <collapsible-options
        v-if="activeAnalysis.assetType == assetTypes.servicing"
        :value="msrValueDecomposition"
        @input="$emit('update:msrValueDecomposition', $event)"
        title="MSR Value Decomposition" 
        :collapsible="false"
      />
    </collapsible-section>

    <parallel-shocks-modal
      id="shocks"
    />
  </div>
</template>

<script>
import CollapsibleOptions from './CollapsibleOptions.vue'
import CollapsibleSection from './CollapsibleSection.vue'
import FileSelectWithCheckbox from './FileSelectWithCheckbox.vue'
import ParallelShocksModal from './ParallelShocksModal.vue'
import { fileNames } from '../js/fileInfo'
import { mapMutations, mapState } from 'vuex'
import createId from '../js/mixins/createId'
import { assetTypes } from '../js/options/assetType'
import validationState from '../js/mixins/validationState'

export default {
  props: {
    monteCarlo: { type: Object, required: true },
    payup: { type: Boolean, required: true },
    payupHorizon: { type: [Number,String], required: true },
    forwardCurves: { type: Boolean, required: true },
    msrValueDecomposition: { type: Boolean, required: true },
    fieldConfigMetaData: { type: Object, required: true },
    enableCapitalCharge: { type: Boolean, required: true },
    v: { type: Object, required: true }
  },
  data () {
    return {
      assetTypes,
      fileNames,
      interestRateRiskTypes: [
        { name: "effectiveDuration", description: "Effective Duration / Convexity" },
        { name: "mortgageSpread", description: "Mortgage Spread Duration" },
        { name: 'vega', description: 'Vega' },
        { name: "keyRateDurations", description: "Key Rate Durations / Convexities" }
        //{ name: 'showycdur', description: 'Yield Curve Duration' }
      ],
      modelRiskTypes: [
        { name: "prepaySensitivity", description: "Prepay Scale Sensitivity" },
        { name: "refiSensitivity", description: "Refi Scale Sensitivity" },
        { name: "turnoverSensitivity", description: "Turnover Scale Sensitivity" },
        { name: "sCurveSensitivity", description: "S-Curve Slide Sensitivity" },
        { name: "ficoSensitivity", description: "Original FICO Sensitivity" },
        { name: "ltvSensitivity", description: "Original LTV Sensitivity" }
      ],
      capitalChargeFields: [
        { name: "riskFreeRate", label: "Risk Free Rate (%)" },
        { name: "confidenceLevel0", label: "Confidence Level 0" },
        { name: "confidenceLevel1", label: "Confidence Level 1" },
        { name: "confidenceLevel2", label: "Confidence Level 2" },
        { name: "confidenceLevel3", label: "Confidence Level 3" },
        { name: "returnOnEquity0", label: "Return on Equity 0 (%)" },
        { name: "returnOnEquity1", label: "Return on Equity 1 (%)" },
        { name: "returnOnEquity2", label: "Return on Equity 2 (%)" },
      ]

      //  durationtypes: [
      //      {name: 'showoasdur', description: 'OAS Duration'},
      //      {name: 'showeffdur', description: 'Effective Duration/Convexity'},
      //      {name: 'showkeydur', description: 'Key Rate Duration/Convexity'},
      //      {name: 'showltvficohpidur', description: 'LTV, FICO, HPI Duration/Convexity'},
      //      {name: 'showspreaddur', description: 'Mortgage Spread Duration'},
      //      {name: 'showVega', description: 'Vega'},
      //      {name: 'showslidedur', description: 'Slide Duration'},
      //      {name: 'showturndur', description: 'Turnover Duration'},
      //      {name: 'showrefidur', description: 'Refi Duration'},
      //      {name: 'showppdur', description: 'Prepay Duration'},
      //      {name: 'showycdur', description: 'Yield Curve Duration'},
      //  ],
      // durationshifttypes: [
      //   { name: "oasdur" },
      //   { name: "effdur" },
      //   { name: "keydur" },
      //   {
      //     name: "ltvslide",
      //     durationname: ["showltvficohpidur"],
      //     description: "LTV slide"
      //   },
      //   {
      //     name: "ficoslide",
      //     durationname: ["showltvficohpidur"],
      //     description: "LTV slide"
      //   },
      //   {
      //     name: "hpidrift",
      //     durationname: ["showltvficohpidur"],
      //     description: "LTV slide"
      //   },
      //   {
      //     name: "hpidiff0",
      //     durationname: ["showltvficohpidur"],
      //     description: "LTV slide"
      //   },
      //   { name: "spreaddur" },
      //   { name: "Vega" },
      //   { name: "slidedur" },
      //   {
      //     name: "ppdur",
      //     durationname: ["showppdur", "showturndur", "showrefidur"]
      //   }
      // ],
      
    }
  },
  computed: {
    ...mapState(['activeAnalysis', 'validationStatus']),
    parallelShocksValid () {
      return this.validationStatus.length == 0 ||
      (this.validationStatus.find(s => s.item == 'parallel-shocks-modal') != undefined && this.validationStatus.find(s => s.item == 'parallel-shocks-modal' && s.isValid) != undefined);
    }
  },
  methods: {
    ...mapMutations(["toggleFile"]),

    getUpdateData (name, value) {
      let data = {};
      data[name] = value;
      return data;
    }

    // durationShiftEnable (duration) {
    //   if (!this.calcs.showdurationshifts) return false;
    //   let durationtype = duration.durationname;
    //   if (durationtype && durationtype.length) {
    //     // array check any
    //     for (let i = 0; i < durationtype.length; i++) {
    //       if (this.val("durations." + durationtype[i])) return true;
    //     }
    //     return false;
    //   }
    //   if (durationtype == undefined) durationtype = "show" + duration.name;
    //   return this.val("durations." + durationtype);
    // },
    // durationShiftDescription (duration) {
    //   let durationdesc = duration.description;
    //   if (durationdesc != undefined) return durationdesc;
    //   let durationtype = duration.durationname;
    //   if (durationtype && durationtype.length) {
    //     let retval = "";
    //     // array check any
    //     for (let i = 0; i < durationtype.length; i++) {
    //       let element = this.durationtypes.find(
    //         elem => elem.name == durationtype[i]
    //       );
    //       let tempval = element.description;
    //       if (i > 0) retval += ", ";
    //       retval += tempval;
    //     }
    //     return retval;
    //   }
    //   if (durationtype == undefined) durationtype = "show" + duration.name;
    //   return this.durationtypes.find(elem => elem.name == durationtype)
    //              .description;
    // }
  },
  watch: {
    isValidating (val) {
      if (val === true) {
        this.v.$touch();

        this.addValidationStatus({
          tab: 'Calculations',
          item: 'value-and-risk-panel',
          isValid: !this.v.$invalid
        });
      }
    }
  },
  components: {
    FileSelectWithCheckbox,
    CollapsibleOptions,
    CollapsibleSection,
    ParallelShocksModal
  },
  mixins: [createId, validationState]
}
</script>
