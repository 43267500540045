<template>
  <div class="input-files">
    <b-alert
      class="ml-3 mr-3"
      :show="!!errorMessage"
      variant="danger"
    >
      {{ errorMessage }}
    </b-alert>
    <loading-indicator v-if="loading" />
    <div
      v-else-if="!errorMessage"
      v-disable-all="analysisRunning"
    >
      <b-alert
        :show="!tabValid('Inputs')"
        variant="danger"
      >
        Please fix the validation errors shown below
      </b-alert>
      
      <b-navbar
        v-if="activeAnalysis.runType == 'RiskValDynamics'"
        toggleable="sm"
      >
        <b-navbar-toggle target="nav-collapse" />
        <b-collapse
          id="nav-collapse"
          is-nav
        >
          <!-- <b-navbar-nav class="mr-5">
            <b-nav-form>
              <label for="market-date">Date: </label>
              <b-form-select id="market-date" v-model="marketDate" :options="rates" size="sm">
                <template v-slot:first>
                  <b-form-select-option :value="null">-- select --</b-form-select-option>
                </template>
              </b-form-select>
              <icon-button icon="archive" size="sm" @click="loadRates" :disabled="marketDate == null" bordered>Load Rates</icon-button>
            </b-nav-form>
          </b-navbar-nav> -->

          <b-navbar-nav>
            <b-nav-form>
              <b-form-checkbox
                v-if="activeAnalysis.assetType == 'Servicing'"
                class="ml-3"
                :checked="activeAnalysis.settings.useHedgeFile"
                switch
                @input="setHedgeFile"
              >
                Enable Hedges
              </b-form-checkbox>
              <b-form-checkbox
                v-if="activeAnalysis.assetType == 'AgencyPools'"
                class="ml-3"
                :checked="activeAnalysis.settings.payup"
                switch
                @input="setTbaFile"
              >
                Enable Payup
              </b-form-checkbox>

              <b-form-group
                label="Volatility Type"
                label-for="volatility-type"
                :label-cols="4"
                style="width: 400px"
                class="ml-5"
              >
                <b-select
                  id="volatilityType"
                  class="field-md"
                  :value="activeAnalysis.settings.volatilityType"
                  :options="volatilityTypeOptions"
                  size="sm"
                  @change="setSwaptionVolatilityType"
                />
              </b-form-group>
            </b-nav-form>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <file-select-wrapper
                v-if="!licenseInfo.demoMode || isAdmin"
                multiple
                @input="uploadFileList(activeAnalysis.id, $event)"
              >
                <span
                  v-b-tooltip.hover
                  class="icon-button theme-item bordered"
                  tabindex="0"
                  :title="`Allows selecting all files at once from a folder. Each filename must contain one of the following identifiers: ${fileIdentifiers}.`"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="folder"
                  />
                  <span>Load from folder</span>
                </span>
              </file-select-wrapper>
            </b-nav-form>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <scroll-container>
        <data-table
          class="table-modern theme-item"
          :data="files"
        >
          <template slot="columns">
            <tr>
              <th>File Type</th>
              <th class="text-center">
                Status
              </th>
              <th class="d-none d-lg-table-cell text-right">
                Record Count
              </th>
              <th class="d-none d-xl-table-cell text-center">
                Last Modified Date
              </th>
              <th class="text-center">
                Actions
              </th>
            </tr>
          </template>
          <template slot-scope="{row}">
            <tr :class="{ invalid: row.status == fileStatus.missing && row.dirty }">
              <td>{{ row.fileType }}</td>
              <td
                class="text-center"
                style="min-width: 170px;"
              >
                <upload-progress 
                  v-if="row.status == fileStatus.uploading" 
                  :progress="row.uploadProgress" 
                  @cancel-upload="cancelUpload(row.uploadCancelToken)"
                />
                <b-badge
                  v-else
                  :variant="statusInfo[row.status].variant"
                >
                  {{ statusInfo[row.status].label }}
                </b-badge>
              </td>
              <td class="d-none d-lg-table-cell text-right">
                {{ row.recordCount && row.recordCount.toLocaleString() }}
              </td>
              <td class="d-none d-xl-table-cell text-center">
                {{ row.modifiedDate || '' | formatDate }}
              </td>
              <td
                class="table-actions text-center"
                style="width: 300px;"
              >
                <b-dropdown
                  ref="addDataDropDown"
                  v-b-tooltip.hover
                  toggle-class="icon-button theme-item"
                  title="Add Data"
                  no-caret
                >
                  <template slot="button-content">
                    <font-awesome-icon
                      title="Add Data"
                      icon="download"
                      class="input-files-icon"
                      size="lg"
                    />
                  </template>
                  <b-dropdown-item @click="confirmReplaceData(row, null, true)">
                    Create from template
                  </b-dropdown-item>
                  
                  <b-dropdown-item 
                    @click.native.capture.stop="importClick"
                  >
                    <upload-button 
                      v-if="!licenseInfo.demoMode || isAdmin"
                      :ref="`input${row.fileName}`"
                      wrapper-class=""
                      :disabled="!canUpload(row.status)"
                      @input="confirmReplaceData(row, $event)"
                      @error="fileError(row.fileName, $event)"
                    >
                      Import from file
                    </upload-button>
                    <span v-else>Import from file</span>
                  </b-dropdown-item>

                  <b-dropdown-item 
                    v-if="showCusipImport(row)"
                    @click="showCusipDialog = true"
                  >
                    Import CUSIPs
                  </b-dropdown-item>

                  <b-dropdown-item 
                    v-if="showCusipImport(row)"
                    @click.native.capture.stop="importClick"
                  >
                    <upload-button 
                      v-if="!licenseInfo.demoMode || isAdmin"
                      :ref="`input${row.fileName}`"
                      wrapper-class=""
                      :disabled="!canUpload(row.status)"
                      @input="confirmCusipImport(row, $event)"
                      @error="fileError(row.fileName, $event)"
                    >
                      Import from CUSIP file
                    </upload-button>
                    <span v-else>Import from CUSIP file</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="showFileLayout(row)">
                    View File Layout
                  </b-dropdown-item>
                </b-dropdown>

                <icon-button
                  v-b-tooltip.hover
                  icon="edit"
                  title="Edit File"
                  class="btn"
                  icon-class="input-files-icon"
                  size="lg" 
                  :disabled="!canEdit(row.status)"
                  @click="editFile(row.route)" 
                />
                  
                <icon-button
                  v-b-tooltip.hover
                  icon="times-circle"
                  title="Remove File"
                  class="btn remove-file"
                  icon-class="input-files-icon"
                  size="lg"
                  :disabled="disableRemoveIcon(row)"
                  @click="confirmDelete(row)"
                />
              </td>
            </tr>
          </template>
        </data-table>
      </scroll-container>
    </div>

    <modal-dialog
      ref="confirmDeleteDialog"
      title="Delete Data"
      :prompt="`Are you sure you want to delete the ${ clickedFile.fileType } data?`"
      :action="deleteData"
      error-message="An error occurred deleting the data."
      confirm-button-text="Delete"
    />

    <modal-dialog
      ref="confirmReplaceDialog"
      title="Replace Data"
      :prompt="`Are you sure you want to replace the existing ${ clickedFile.fileType } data with new data?`"
      error-message="An error occurred replacing the data."
      confirm-button-text="Replace"
      :action="replaceData"
      @confirmed="confirmedReplaceData"
    />

    <modal-dialog
      ref="importDisabledDialog"
      title="Import Disabled"
      :prompt="`File imports are not available for demo accounts. Please select 'Create from template'.`"
      confirm-button-text="OK"
      ok-only
    />

    <import-results-dialog
      ref="importDialog"
      :import-results="importResults"
    />

    <file-layout-dialog
      ref="fileLayoutDialog"
      :file="clickedFile"
    />

    <modal-dialog
      v-model="showCusipDialog"
      title="Import CUSIP Data"
      :action="postCusips"
      :error-message="cusipError"
      confirm-button-text="Import"
    >
      <span>Enter or paste in CUSIPs one per line. Pool balance can also be included, separated by tab</span>
      <b-form-textarea
        v-model="$v.cusips.$model"
        placeholder="Enter CUSIPs"
        rows="5"
        max-rows="15"
        :state="state($v.cusips)"
      />
      <b-form-invalid-feedback :state="state($v.cusips)">
        At least one CUSIP is required.
      </b-form-invalid-feedback>
    </modal-dialog>
  </div>
</template>

<script>
import DataTable from  './DataTable.vue'
import FileSelectWrapper from './FileSelectWrapper.vue'
import UploadProgress from './UploadProgress.vue'
import IconButton from './IconButton.vue'
import UploadButton from './UploadButton.vue'
import LoadingIndicator from './LoadingIndicator.vue'
import ModalDialog from './ModalDialog.vue'
import ImportResultsDialog from './ImportResultsDialog.vue'
import FileLayoutDialog from './FileLayoutDialog.vue'
import ScrollContainer from './ScrollContainer.vue'
import formatters from '../js/formatters'
import { fileStatus, fileNames } from '../js/fileInfo'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import fileUpload from '../js/mixins/fileUpload'
import api from '../api'
import { assetTypes } from '../js/options/assetType'
import { runTypes } from '../js/options/runType'
import { volatilityTypeOptions } from '../js/options/volatilityTypeOptions'
import { required } from 'vuelidate/lib/validators'
import validationState from '../js/mixins/validationState'

export default {
  props: {
    id: { type: [String, Number], required: true }
  },
  data () {
    return {
      loading: true,
      statusInfo: [
        { label: 'Missing', variant: 'danger' },
        { label: 'Selected', variant: 'success' },
        { label: 'Imported', variant: 'success' },
        { label: 'Optional', variant: 'info' },
        { label: 'Uploading', variant: 'warning' }
      ],
      editorData: [],
      editorSavePath: null,
      editorOnSave: null,
      fileStatus,
      marketDate: null,
      //rates: [],
      errorMessage: null,
      clickedFile: {},
      importResults: {},
      selectedUpload: null,
      fileLayout: [],
      useTemplate: false,
      runTypes,
      volatilityTypeOptions,
      showCusipDialog: false,
      cusips: null,
      cusipError: ''
    }
  },
  async mounted () {
    try
    {
      // getInputsList is called by AnalysisEditor
      //await this.getInputsList();
      // let rates = await api.getRatesList();
      // this.rates = rates.map(r => ({ value: r.dateKey, text: `${r.month < 10 ? '0' + r.month : r.month}/${r.year}` }));
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.errorMessage = 'Error loading input files tab';
      // eslint-disable-next-line
      console.error(err);
    }

  },
  validations: {
    cusips: { required }
  },
  computed: {
    ...mapState(['fileList', 'activeAnalysis', 'licenseInfo']),
    ...mapGetters(['calcs', 'fileSet', 'tabValid', 'analysisRunning', 'isAdmin']),

    files () {
      return this.fileSet.filter(f => f.enabled && !f.hidden && f.inputType!="UnemploymentForecast");
    },
    fileIdentifiers () {
      return this.files.map(f => f.fileName.toLowerCase()).join(', ');
    }
  },
  methods: {
    ...mapMutations(['updateFile', 'enableHedges', 'enablePayup', 'clearValidationStatus', 'setVolatilityType']),
    ...mapActions(['getInputsList', 'autoSave']),

    async createFromTemplate (fileInfo) {
      await this.$http.post(`analyses/${this.activeAnalysis.id}/templates/${fileInfo.inputType}?overwrite=true`);
      this.editFile(fileInfo.route);
    },

    showCusipImport (row) {
      return this.activeAnalysis.assetType == assetTypes.agencyPools && row.fileName == fileNames.loans;
    },

    async confirmCusipImport (fileInfo, file) {
      let f = Object.assign({}, fileInfo);
      f.route = 'cusips/file';
      f.deleteRoute = 'Positions';

      await this.confirmReplaceData(f, file);
    },

    async confirmReplaceData (fileInfo, file, useTemplate) {
        if (fileInfo.status == fileStatus.posted) {
          this.clickedFile = fileInfo;
          this.selectedUpload = file;
          this.useTemplate = !!useTemplate;
          this.$refs.confirmReplaceDialog.showDialog();
        } else {
          if (useTemplate) {
            await this.createFromTemplate(fileInfo);
          } else {
            await this.beginUpload(fileInfo, file);
          }
        }
    },
    
    // use dialog action so dialog waits until created from template is complete
    async replaceData () {
      if (this.useTemplate) {
        await this.createFromTemplate (this.clickedFile);
      }
    },

    // Trigger using confirmed event so the dialog closes before starting the upload
    async confirmedReplaceData () {
     if (!this.useTemplate) {
        await this.beginUpload(this.clickedFile, this.selectedUpload);
      }
    },

    async beginUpload (fileInfo, file) {
      try {

        // Delete any existing data
        await api.deleteInput(this.activeAnalysis.id, fileInfo.deleteRoute || fileInfo.route);

        // Upload file
        this.importResults = await this.uploadFile(this.activeAnalysis.id, fileInfo, file, this.uploadCanceled);
      
        if (this.importResults.errors && this.importResults.errors.length) {
          // Show import results
          this.$refs.importDialog.showDialog();
        }

      } catch (err) {
        let msg = err.message || "";

        this.messageBox('Upload Error', `An error occurred uploading your file. ${msg}`)
          .then(() => {
            this.updateFile({ 
              fileName: fileInfo.fileName, 
              fileInfo: {
                originalName: null,
                status: fileInfo.optional ? fileStatus.optional : fileStatus.missing,
                recordCount: null,
                modifiedDate: null,
                file: null,
                uploadProgress: 0,
                uploadCancelToken: null
              }
            });
          });
      }

      this.clearFileSelect(fileInfo.fileName);
    },

    confirmDelete (fileInfo) {
      this.clickedFile = fileInfo;
      this.$refs.confirmDeleteDialog.showDialog();
    },

    async deleteData () {
      
      await this.$http.delete(`analyses/${this.activeAnalysis.id}/inputs/${this.clickedFile.route}`);
      
      this.updateFile({ 
        fileName: this.clickedFile.fileName, 
        fileInfo: {
          originalName: null,
          status: this.clickedFile.optional ? fileStatus.optional : fileStatus.missing,
          recordCount: null,
          modifiedDate: null,
          file: null,
          uploadProgress: 0,
          uploadCancelToken: null
        }
      });

      this.clearFileSelect(this.clickedFile.fileName);
    },
    
    editFile (route) {
      this.$router.push(`/edit/${this.activeAnalysis.id}/${route}`);
    },
    canUpload (status) {
      return (status != fileStatus.uploading && !this.analysisRunning);
    },
    canEdit (status) {
      return (status == fileStatus.selected || status == fileStatus.posted);
    },
    disableRemoveIcon (row) {
      let disabled = row.status != fileStatus.posted && row.status != fileStatus.selected
      return disabled
    },
    uploadCanceled (fileInfo) {
      this.$refs[`input${fileInfo.fileName}`].clear();
    },
    fileError (fileName, ext) {
      this.messageBox('Invalid file extension', `Invalid file extension: .${ext}. Please upload a tab delimited text file.`);

      this.clearFileSelect(fileName);
    },
    clearFileSelect (fileName) {
      let fileSelect = this.$refs[`input${fileName}`];

      if (fileSelect) { 
       fileSelect.clear();
      }
    },
    messageBox (title, message) {
      return this.$bvModal.msgBoxOk(message, {
        title: title,
        headerClass: 'pl-2 pr-2 pt-1 pb-1',
        footerClass: 'p-2 border-top-0'
      });
    },
    setHedgeFile (enabled) {
      this.enableHedges(enabled);
      let data = this.activeAnalysis.settings
      data.runType = this.activeAnalysis.runType
      this.autoSave({ saveAction: 'saveAnalysisSettings', isValid: true, id: this.activeAnalysis.id, data: data });
    },
    setTbaFile (enabled) {
      this.enablePayup(enabled);
      let data = this.activeAnalysis.settings
      data.runType = this.activeAnalysis.runType
      this.autoSave({ saveAction: 'saveAnalysisSettings', isValid: true, id: this.activeAnalysis.id, data: data });
    },
    setSwaptionVolatilityType (volatilityType) {
      this.setVolatilityType(volatilityType);
      let data = this.activeAnalysis.settings
      data.runType = this.activeAnalysis.runType
      this.autoSave({ saveAction: 'saveAnalysisSettings', isValid: true, id: this.activeAnalysis.id, data: data });
    },
    async loadRates () {
      try {
        let confirmed = true;

        // Confirm data replacement if data has been imported
        if (this.hasRatesData()) {
          confirmed = await this.$bvModal.msgBoxConfirm('Replace existing data for Current Coupon Yields, Treasury and Libor Rates, and Swap Volatilities?');
        }

        // If replacement confirmed replace data
        if (confirmed) {
          await api.loadRates(this.activeAnalysis.id, this.marketDate);
          await this.getInputsList();
          this.messageBox('Market Rates Updated', 'Market rate data has been updated.');
        }
      }
      catch (err) {
        this.messageBox('Error loading market rates.', 'An error occurred loading rates data.');
      }
    },
    hasRatesData () {
      return this.fileList[fileNames.ccys].status == fileStatus.posted ||
        this.fileList[fileNames.tsylibs].status == fileStatus.posted ||
        this.fileList[fileNames.swapvols].status == fileStatus.posted;
    },
    showFileLayout (fileInfo) {
      this.clickedFile = fileInfo;

      this.$nextTick(() => {
        this.$refs.fileLayoutDialog.showDialog();
      });
    },
    importClick () {
      if (this.licenseInfo.demoMode && !this.isAdmin) {
        this.$refs.importDisabledDialog.showDialog();
      } else {
        // Simulate a click to close the drop down
        this.$nextTick(() => {
          document.body.click();
        });
      }
    },
    async postCusips () {
      // Set default error message
      this.cusipError = 'An error occurred importing CUSIP data.'

      if (this.$v.cusips.$invalid) {
        return;
      }

      // Split by new line handling both \n and \r\n
      const cusips = this.cusips.split(/\r?\n/).map(l => {
        const line = l.split('\t');
        return { cusip: line[0], balance: line[1] || null };
      })

      try {
        // filter out blank cusips
        await api.postCusips(this.activeAnalysis.id, cusips.filter(c => !!c.cusip));
        this.cusips = '';
      }
      catch (err) {
        // Set custom error message if available
        if (err.response.data.message) {
          this.cusipError = err.response.data.message;
        }
        throw err;
      }

      await this.getInputsList();
    }
  },
  watch: {
    calcs: {
      deep: true,
      async handler(newValue, oldValue) {
        if (newValue.volatilityType != oldValue.volatilityType) {
          this.clearValidationStatus();
          await this.getInputsList();
        }
      }
    }
  },
  components: {
    DataTable,
    FileSelectWrapper,
    IconButton,
    UploadButton,
    UploadProgress,
    LoadingIndicator,
    ModalDialog,
    ImportResultsDialog,
    FileLayoutDialog,
    ScrollContainer
  },
  mixins: [formatters, fileUpload, validationState]
}
</script>

<style scoped>
.badge {
  padding: 0.5em 1em;
}

.table {
  cursor: default;
}

.table > tbody > tr.invalid > td {
  background-color: #f8d7da;
}

.table > tbody > tr.invalid:hover > td {
  background-color: #fad3d6;
}

.template-icon {
  font-size: 1.5em;
}

.form-inline label {
  margin-right: 5px;
}

.btn {
  font-size: 0.9em;
}

.button-group {
  margin-left: 15px;
}

.dropdown-item > .svg-inline--fa {
    margin-right: 2px;
}
</style>

