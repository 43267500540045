<template>
  <div class="parallel-shocks-modal">
    <b-modal
      :id="id" 
      size="md"
      title="Parallel Shocks"
      ok-title="Save"
      :ok-disabled="$v.shockValues.$anyError || !shockValues.length"
      no-close-on-backdrop
      @ok="save"
      @cancel="revert"
      @close="revert"
    >
      <b-container>
        <b-row
          v-for="(shock, shockIdx) in shockValues"
          :key="shockIdx"
        >
          <b-col
            cols="8"
          >
            <b-form-group
              :label="`Shock ${shockIdx+1}`"
              :label-for="`shock-${shockIdx}`"
              :label-cols="true"
              :title="`Parallel shock ${shockIdx+1}`"
            >
              <b-form-input
                :id="`shock-${shockIdx}`"
                v-model="$v.shockValues.$each[shockIdx].shock.$model"
                type="text"
                class="field-sm"
                size="xs"
                autocomplete="off"
                :state="state($v.shockValues.$each[shockIdx])"
              />
              <b-form-invalid-feedback :state="state($v.shockValues.$each[shockIdx])">
                {{ validationMessage($v.shockValues.$each[shockIdx].shock) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            cols="1"
            class="delete-shock"
          >
            <b-form-group>
              <icon-button
                v-b-tooltip.hover
                icon="trash-alt"
                title="Delete shock"
                size="md"
                @click="deleteShock(shockIdx)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-btn
          variant="outline-secondary"
          @click="addShock()"
        >
          <font-awesome-icon :icon="['far','plus-square']" />
          Add Shock
        </b-btn>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import IconButton from './IconButton.vue'
import { mapState, mapMutations } from 'vuex'
import { decimal, required, minLength } from 'vuelidate/lib/validators'
import validationState from '../js/mixins/validationState'
import api from '../api'

export default {
  props: {
    id: { type: String, required: true }
  },
  data () {
    return {
      shocks: [],
      shockValues: []
    }
  },
  async mounted() {
    this.shocks = await api.getParallelShocks(this.activeAnalysis.id);
    this.shocks = this.shocks.map((s) => { return s.shock });
    this.shockValues = this.initShockValues(this.shocks);
  },
  validations: {
    shockValues: {
      minLength: minLength(1),
      $each: {
        shock: {
          required,
          decimal,
          $autoDirty: true
        }
      }
    }
  },
  computed: {
    ...mapState(['activeAnalysis', 'isValidating'])
  },
  methods: {
    ...mapMutations(['setAnalysisModified', 'addValidationStatus']),
    initShockValues(shocks) {
      return shocks.map(s => {
        return { shock: s }
      });
    },
    addShock () {
      this.shockValues.push({ shock: 0 });
    },
    deleteShock(idx) {
      this.shockValues.splice(idx, 1);
    },
    revert () {
      this.shockValues = this.initShockValues(this.shocks);
    },
    async save (evt) {
      if (this.$v.shockValues.$anyError) {
        evt.preventDefault();
        return;
      }
      
      await api.deleteParallelShocks(this.activeAnalysis.id);
      await api.updateParallelShocks(this.activeAnalysis.id, this.shockValues);

      this.shocks = await api.getParallelShocks(this.activeAnalysis.id);
      this.shocks = this.shocks.map((s) => { return s.shock });
      this.shockValues = this.initShockValues(this.shocks);

      this.setAnalysisModified();
    }
  },
  watch: {
    isValidating (val) {
      if (val === true) {
        this.$v.$touch();

        this.addValidationStatus({
          tab: 'Calculations',
          item: 'parallel-shocks-modal',
          isValid: !this.activeAnalysis.settings.monteCarlo.parShock || (!this.$v.$invalid && this.shocks.length > 0)
        });
      }
    }
  },
  components: {
    IconButton
  },
  mixins: [validationState]
}
</script>
<style scoped>
.delete-shock {
  margin-left: 0;
}
</style>