import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    theme: 'light-theme',
    activeAnalysis: null,
    activeModule: null,
    token: null,
    fileList: {},
    fileNames: [],
    isValidating: false,
    validationStatus: [],
    isSaving: 0,
    savePending: false,
    isSaved: false,
    lastSaved: null,
    saveError: null,
    appTitle: '',
    checkedLicense: false,
    licenseInfo: { hasLicenseKey: false, modules: [], demoMode: false, demoMaxPositions: 0 },
    exportList: [],
    selectedFilters: {},
    selectedFields: {},
    scenarios: [],
    scenarioRecordCount: null,
    scenarioModifiedDate: null,
    scenarioData: {
      Rate: null,
      Hpi: null,
      Unemployment: null
    },
    scenarioPreviewData: {},
    penaltyPointSets: [],
    pointsData: [],
    importResultsEnabled: null,
    copyAdcoDataFiles: null,
    analysisPage: 1,
    analysisPageScrollPosition: 0,
    authentication: {
      enabled: null,
      token: null,
      server: null,
      remoteConnection: false
    },
    portfolioGridDirty: false,
    scenarioGridDirty: false,
    appConfig: null,
    cashFlowSelections: { type: null, loanId: null, pathId: null, scenarioType: null }
  },
  getters,
  mutations,
  actions
})
