import { createCalcs } from './calcs'
import positionsGridOptions from './gridOptions/positionsGridOptions'
import hedgePositionsGridOptions from './gridOptions/hedgePositionsGridOptions'
import tbaPositionsGridOptions from './gridOptions/tbaPositionsGridOptions'
import ccyGridOptions from './gridOptions/ccyGridOptions'
import yieldCurveGridOptions from './gridOptions/yieldCurveGridOptions'
import swapVolsGridOptions from './gridOptions/swapVolsGridOptions'
import swapVolsAbsoluteGridOptions from './gridOptions/swapVolsAbsoluteGridOptions'
import unemploymentForecastGridOptions from './gridOptions/unemploymentForecastGridOptions'
import forecastGridOptions from './gridOptions/forecastGridOptions'
import scenarioGridOptions from './gridOptions/scenarioGridOptions'
import pointsGridOptions from './gridOptions/pointsGridOptions'
import api from '../api'

let fileStatus = {
  missing: 0,
  selected: 1,
  posted: 2,
  optional: 3,
  uploading: 4
}

let fileNames = {
  loans: 'LOANS',
  tbaLoans: 'TBALOANS',
  ldmForecast: 'ldmforecast',
  unemploymentForecast: 'fcst_unemployment.txt',
  scenarioForecast: 'fcst_10yr.txt',
  penaltyPoints: 'penalty_points_template.txt',
  forecast: 'fcst_example.txt',
  swapvols: 'swapvols',
  swapvols_abs: 'swapvols_abs',
  tsylibs: 'tsylibs',
  ccys: 'ccys',
  tuningConditions: 'tuningconditions_custom.txt',
  tuningValues: 'tuningvalues_custom.txt',
  hpaTune: 'hpatune.txt',
  yieldCurveShock: 'YCshock.txt',
  //parShock: 'parshock.txt',
  gausscorr: 'gausscorr',
  customCommands: '_commands',
  commands: 'commands',
  hedgeLoans: 'HEDGELOANS',
  scenarios: 'scenarios',
  stressScenarios: 'ST_scenarios',
  stressTestRepricing: 'ST_repricing.txt',
  scenarioGridRepricing: 'repricing.txt',
  ceclScenarios: 'CECL_scenarios',
  ceclRepricing: 'CECL_repricing.txt',
  hpaZipMap: 'hpazipmap_custom.txt',
  unempZipMap: 'unempzipmap_custom.txt',
  customHpaFile: 'HPA_custom.txt',
  customUnempFile: 'unemployment_custom.txt'
}

function getInputFileList(analysis) {
  let calcs = createCalcs(analysis);
  let showLdm =  calcs.showLDM;
  let volatilityType = calcs.volatilityType;
  let showMsr = calcs.val('showMSR');
  let isMultiFamilyLoans = analysis.assetType == 'MultiFamilyLoans';
  let isAutoLoans = analysis.assetType == 'AutoLoans';
  let isAgencyPools = analysis.assetType == 'AgencyPools';
  let interestRateRiskEnabled = calcs.enableValueAndRiskMetrics && calcs.showinterestRateRisk;

  return [
    { fileName: fileNames.loans, inputType: 'Position', fileType: isAgencyPools ? 'Pools' :'Portfolio', enabled: true, hasTemplate: true, tab: 'Inputs', route: 'Positions', gridOptions: positionsGridOptions },
    { fileName: fileNames.hedgeLoans, inputType: 'HedgePosition', fileType: 'Hedge Positions', enabled: showMsr && calcs.useHedgeFile, hasTemplate: true, tab: 'Inputs', route: 'HedgePositions', gridOptions: hedgePositionsGridOptions },
    { fileName: fileNames.tbaLoans, inputType: 'TbaPoolPosition', fileType: 'TBA Pools', enabled: isAgencyPools && calcs.showpayup, hasTemplate: true, tab: 'Inputs', route: 'TbaPositions', gridOptions: tbaPositionsGridOptions },
    //{ fileName: fileNames.ldmForecast, inputType: 'LdmForecast', fileType: 'LDM Forecast', enabled: showLdm && !isAutoLoans, hasTemplate: true, tab: 'Inputs', route: 'LdmForecast', gridOptions: ldmForecastGridOptions },
    { fileName: fileNames.scenarioForecast, inputType: 'ScenarioImportModel', fileType: 'Scenario', enabled: showLdm, hasTemplate: true, tab: 'Scenarios', route: '', gridOptions: scenarioGridOptions, optional: true },
    { fileName: fileNames.unemploymentForecast, inputType: 'ScenarioImportModel', fileType: 'Unemployment Scenario', enabled: showLdm, hasTemplate: true, tab: 'Scenarios', route: '', gridOptions: unemploymentForecastGridOptions, optional: true },
    { fileName: fileNames.forecast, inputType: 'ForecastImportModel', fileType: 'Forecast', enabled: showLdm && !isAutoLoans, hasTemplate: true, tab: 'Inputs', route: 'Forecast', gridOptions: forecastGridOptions, optional: true },
    { fileName: fileNames.penaltyPoints, inputType: 'PenaltyPointSetImportModel', fileType: 'Points', enabled: isMultiFamilyLoans, hasTemplate: true, tab: 'Points', route: 'PenaltyPoints', gridOptions: pointsGridOptions, optional: true },
    { fileName: fileNames.ccys, inputType: 'CurrentCouponYield', fileType: 'Current Coupon Yields', enabled: !showLdm, hasTemplate: true, tab: 'Inputs', route: 'CurrentCouponYields', gridOptions: ccyGridOptions },
    { fileName: fileNames.tsylibs, inputType: 'YieldCurve', fileType: 'Yield Curves', enabled: !showLdm, hasTemplate: true, tab: 'Inputs', route: 'YieldCurves', gridOptions: yieldCurveGridOptions },
    { fileName: fileNames.swapvols, inputType: 'SwaptionVolatility', fileType: 'Swaption Volatilities (Proportional)', enabled: !showLdm, hasTemplate: true, tab: 'Inputs', route: 'SwaptionVolatilities', gridOptions: swapVolsGridOptions },
    { fileName: fileNames.swapvols_abs, inputType: 'SwaptionVolatilityAbsolute', fileType: 'Swaption Volatilities (Absolute)', enabled: !showLdm && volatilityType == 'Absolute', hasTemplate: true, tab: 'Inputs', route: 'SwaptionVolatilitiesAbsolute', gridOptions: swapVolsAbsoluteGridOptions },
    { fileName: fileNames.tuningConditions, inputType: 'TuningCondition', fileType: 'Tuning Conditions', hidden: true, enabled: calcs.useCustomTuningFiles, hasTemplate: true, tab: 'Calculations' },
    { fileName: fileNames.tuningValues, inputType: 'TuningValue', fileType: 'Tuning Values', hidden: true, enabled: calcs.useCustomTuningFiles, hasTemplate: true, tab: 'Calculations' },
    { fileName: fileNames.hpaTune, inputType: 'HpaTuning', fileType: 'HPA Tune', enabled: calcs.useCustomHpaTuningFile, hidden: true, hasTemplate: true, tab: 'Calculations' },
    { fileName: fileNames.yieldCurveShock, inputType: 'YieldCurveShock', fileType: 'Yield Curve Shocks', enabled: interestRateRiskEnabled && calcs.interestRateRisk.showycdur, hidden: true, hasTemplate: true, tab: 'Calculations' },
    //{ fileName: fileNames.parShock, inputType: 'ParallelShock', fileType: 'Parallel Shocks', enabled: interestRateRiskEnabled && calcs.interestRateRisk.showparshock, hidden: true, hasTemplate: true, tab: 'Calculations' },
    { fileName: fileNames.gausscorr, inputType: 'GaussCorr', fileType: 'gausscorr', enabled: false, hidden: true, hasTemplate: true, tab: 'Calculations' },
    { fileName: fileNames.customCommands, inputType: 'CustomCommand', fileType: '_commands', enabled: calcs.enableCommandsFile, hidden: true, tab: 'Calculations' },
    { fileName: fileNames.commands, inputType: 'Command', fileType: 'commands', enabled: false, hidden: true },
    { fileName: fileNames.stressTestRepricing, inputType: 'StressTestRepricing', fileType: 'Repricing File', enabled: calcs.showCCAR && calcs.stressTestRepricing, hidden: true, hasTemplate: true, tab: 'Calculations' },
    { fileName: fileNames.ceclRepricing, inputType: 'CeclRepricing', fileType: 'Repricing File', enabled: calcs.showCECL && calcs.ceclRepricing, hidden: true, hasTemplate: true, tab: 'Calculations' },
    { fileName: fileNames.scenarioGridRepricing, inputType: 'ScenarioGridRepricing', fileType: 'Repricing File', enabled: calcs.showcredit && calcs.creditRepricing, hidden: true, hasTemplate: true, tab: 'Calculations' },
    { fileName: fileNames.hpaZipMap, inputType: 'HpaZipMap', fileType: 'HPA Zip Map', enabled: calcs.useHpaZipMap, hidden: true, hasTemplate: true, tab: 'Calculations' },
    { fileName: fileNames.unempZipMap, inputType: 'UnempZipMap', fileType: 'Unemployment Zip Map', enabled: calcs.useUnempZipMap, hidden: true, hasTemplate: true, tab: 'Calculations' },
    { fileName: fileNames.customHpaFile, inputType: 'CustomHpaFile', fileType: 'Custom HPA File', enabled: calcs.useCustomHpaFile, hidden: true, hasTemplate: false, tab: 'Calculations' },
    { fileName: fileNames.customUnempFile, inputType: 'CustomUnempFile', fileType: 'Custom Unemployment File', enabled: calcs.useCustomUnempFile, hidden: true, hasTemplate: false, tab: 'Calculations' }
  ];
}

function getScenarioAnalysisType(scenarioFile)
{
  switch (scenarioFile) {
    case fileNames.scenarios:
      return '20ScenarioGrid';
    case fileNames.stressScenarios:
      return 'StressTest';
    case fileNames.ceclScenarios:
      return 'Cecl';
    default:
        return '20ScenarioGrid';
  }
}

async function setScenarioRepricingFile(analysisId, scenarioFile, repricingFilePath) {
  let scenarios = await api.getFile(analysisId, scenarioFile);

  // Update repricing data file in scenarios
  scenarios.forEach(r => {
    r["REPRICEDataFile"] = repricingFilePath;
  });
  
  let url = api.getFilePath(analysisId, scenarioFile);
  await api.postFile(url, scenarios);
}

export { fileStatus, fileNames, getInputFileList, getScenarioAnalysisType, setScenarioRepricingFile };
