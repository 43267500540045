<template>
  <modal-dialog 
    title="Import Scenarios"
    confirm-button-text="Import"
    :value="value"
    :action="importScenarios"
    @input="$emit('input', $event)" 
    @clear="clearDialog"
  >
    <b-alert
      class="mt-3"
      variant="danger"
      :show="!!message"
    >
      {{ message }}
    </b-alert>
    <b-form-group>
      <b-form-file
        id="scenario-files"
        v-model="scenarioFiles"
        class="mt-3"
        placeholder="Choose your scenario files or drop them here..."
        drop-placeholder="Drop files here..."
        :state="state($v.scenarioFiles)"
        multiple
      >
        <template
          slot="file-name"
          slot-scope="{ names }"
        >
          <b-badge variant="dark">
            {{ names[0] }}
          </b-badge>
          <b-badge
            v-if="names.length > 1"
            variant="dark"
            class="ml-1"
          >
            + {{ names.length - 1 }} More files...
          </b-badge>
        </template>
      </b-form-file>
      <b-form-invalid-feedback :state="state($v.scenarioFiles)">
        {{ validationMessage($v.scenarioFiles) }}
      </b-form-invalid-feedback>
    </b-form-group>
    <div id="upload-info">
      <div>
        <h1>Scenarios File <download-icon @click="downloadTemplate('Scenarios')" /></h1>
        You can upload a single scenarios file. The file name must contain "scenarios" (i.e. scenarios.txt).
      </div>
      <div>
        <h1>HPA Data Files <download-icon @click="downloadTemplate('HpaData')" /></h1>
        Include any HPA data files in your upload that are referenced in the scenarios file using the HPIDataFile column.
      </div>
      <div>
        <h1>Unemployment Data Files <download-icon @click="downloadTemplate('UnemploymentData')" /></h1>
        Include any unemployment data files in your upload that are referenced in the scenarios file using the UNEMPDataFile column.
      </div>
      <div>
        <h1>Interest Rate Data Files <download-icon @click="downloadTemplate('RatesData')" /></h1>
        Include any interest rate data files in your upload that are referenced in the scenarios file using the IRDataFile column.
      </div>
    </div>
  </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog.vue'
import DownloadIcon from './DownloadIcon.vue'
import validationState from '../js/mixins/validationState'
import api from '../api'
import { required } from 'vuelidate/lib/validators'
import getErrorMessage from '../js/getErrorMessage'
import { mapState } from 'vuex'
import * as FileSaver from 'file-saver'

export default {
  props:{
    value: { type: Boolean },
    scenarioRoute: { type: String, default: '20scenariogrid' }
  },
  data () {
    return {
      scenarioFiles: null,
      error: false,
      message: null
    }
  },
  validations: {
    scenarioFiles: {
      required
    }
  },
  computed: {
    ...mapState(['activeAnalysis'])
  },
  methods: {
    async importScenarios () {
      //Validate
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      this.message = null;
      this.error = false;

      try {
        await api.postFile(`/analyses/${this.activeAnalysis.id}/scenarios/${this.scenarioRoute}`, this.scenarioFiles, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        // Check if the import include a scenarios file
        let hasScenarioFile = false;
        for (var i = 0; i < this.scenarioFiles.length; i++) {
          if (this.scenarioFiles[i].name.toLowerCase().includes('scenarios')) {
            hasScenarioFile = true;
            break;
          }
        }

        this.message = 'Scenarios imported.';
        const fileCount = this.scenarioFiles.length;
        this.scenarioFiles = null;
        
        this.$emit('imported', { scenariosImported: hasScenarioFile, fileCount });
        
        this.$nextTick(() => { this.$v.$reset() });
      } catch (err) {
        this.error = true;
        this.message = getErrorMessage(err, 'Error importing scenarios.')
        this.$nextTick(() => { this.$v.$reset() });
        return false;
      }
    },
    clearDialog () {
      this.message = null;
      this.scenarioFiles = null;
    },
    async downloadTemplate (inputType) {
      let resp = await this.$http.get(`analyses/${this.activeAnalysis.id}/templates/${inputType}`);

      let blob = new Blob([resp.data], { type: "text/plain;charset=utf-8" });
      let target = `${inputType} example.txt`;

      FileSaver.saveAs(blob, target);
    },
  },
  components: {
    ModalDialog,
    DownloadIcon
  },
  mixins: [validationState]
}
</script>

<style scoped>
#upload-info {
  font-size: 0.9em;
}

#upload-info h1
 {
  font-size: 0.9em;
  font-weight: bold;
  margin: 5px 0;
}
</style>